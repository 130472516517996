import React, { useContext } from "react";

import { ColorSAP, FONT_FAMILY, Typography } from "@commsult/ontego-ui";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { AppContext } from "../../../App";
import Spinner from "../../../ReusableComponents/Spinner/Spinner";

const GRID_TEMPLATE_COLUMNS = `30px 250px 250px 100px`
const GRID_COLUMN_GAP = `8px`

const Header = () => {
    const { t } = useTranslation()

    return (
        <Box
            sx={{
                padding: '0px 12px',
                boxSizing: 'border-box',
                width: '100%',
                height: 40,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                background: ColorSAP.primary[20],
                display: 'grid',
                alignItems: 'center',
                gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
                gridColumnGap: GRID_COLUMN_GAP
            }}
        >
            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {`#`}
                </Typography>
            </Box>

            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {t(`adminDashboard.clientName`)}
                </Typography>
            </Box>

            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {t(`adminDashboard.clientEmail`)}
                </Typography>
            </Box>

            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {t(`adminDashboard.loginCount`)}
                </Typography>
            </Box>
        </Box>
    )
}

const LoginCountList = props => {
    const {
        handleShowErrorSnackbar,
        handleShowSnackbar
    } = useContext(AppContext)

    const { t } = useTranslation()

    const { loading, loginCounts } = props

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 40,
        minHeight: 40
    })

    const virtualMessageRowRenderer = ({
        key, // Unique key within array of rows
        index, // Index of row within collection
        parent,
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style // Style object to be applied to row (to position it)
    }) => {
        return (
            <CellMeasurer
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
            >
                <div style={style}>
                    <Box
                        sx={{
                            padding: '0px 12px',
                            height: '40px',
                            display: 'grid',
                            alignItems: 'center',
                            gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
                            gridColumnGap: GRID_COLUMN_GAP,
                            borderBottom: `1px solid ${ColorSAP.neutral[20]}`,
                            background: index % 2 !== 0 ? ColorSAP.neutral[10] : ColorSAP.neutral[0]
                        }}
                    >
                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                size={12}
                                color={ColorSAP.neutral[100]}
                            >
                                {index + 1}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                size={12}
                                color={ColorSAP.neutral[100]}
                            >
                                {loginCounts[index].name ?? '-'}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={12}
                                color={ColorSAP.neutral[100]}
                            >
                                {loginCounts[index].email ?? '-'}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={12}
                                color={ColorSAP.neutral[60]}
                            >
                                {loginCounts[index].count}
                            </Typography>
                        </Box>
                    </Box>
                </div>
            </CellMeasurer>
        )
    }

    return (
        <Box>
            <Header />
            <Box
                sx={{
                    width: '100%',
                    height: 225,
                    background: ColorSAP.neutral[5]
                }}
            >
                {loading ? (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Spinner
                            style={{
                                width: 80,
                                height: 80,
                                color: ColorSAP.primary[70]
                            }}
                        />
                    </Box>
                ) : (
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                rowCount={loginCounts.length}
                                width={width}
                                height={height}
                                deferredMeasurementCache={cache}
                                rowHeight={cache.rowHeight}
                                rowRenderer={virtualMessageRowRenderer}
                                overscanRowCount={3}
                            />
                        )}
                    </AutoSizer>
                )}
            </Box>
        </Box>
    )
}

export default LoginCountList