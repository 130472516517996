
import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PlusIcon from '../../../Icons/PlusIcon';
import AdminUserService from '../../../Pages/AdminDashboard/AdminUserService';
import DialogBox from '../../../ReusableComponents/DialogBox/DialogBox';
import Spinner from '../../../ReusableComponents/Spinner/Spinner';
import TertiaryButton from '../../../ReusableComponents/TertiaryButton/TertiaryButton';
import BillingReportList from './BillingReportList';

const BillingReportDialog = props => {
    const { open, onClose, inspectedData } = props

    const [isLoading, setIsLoading] = useState(false)

    const [createInvoiceLoading, setCreateInvoiceLoading] = useState(false)
    const [deleteInvoiceLoading, setDeleteInvoiceLoading] = useState(false)

    const [billingReports, setBillingReports] = useState(null)

    const fetchInvoicesByClientKey = () => {
        setIsLoading(true)
        AdminUserService.getInvoiceByClientKey(inspectedData?.key).then(res => {
            setBillingReports(res.data)
            setIsLoading(false)
            setCreateInvoiceLoading(false)
        }).catch(err => {
            setIsLoading(false)
            setCreateInvoiceLoading(false)
        })
    }

    useEffect(() => {
        if (open) {
            setIsLoading(true)
            fetchInvoicesByClientKey()
        }
    }, [open])

    const handleGenerateNewInvoice = () => {
        setCreateInvoiceLoading(true)
        AdminUserService.generateNewInvoiceByClientKey(inspectedData?.key).then(res => {
            fetchInvoicesByClientKey()
        }).catch(err => {
            setCreateInvoiceLoading(false)
        })
    }

    const handleDeleteInvoiceByInvoiceId = invoiceId => () => {
        setDeleteInvoiceLoading(true)
        AdminUserService.deleteInvoice(invoiceId).then(res => {
            setDeleteInvoiceLoading(false)
            // console.log(res)
            fetchInvoicesByClientKey()
        }).catch(err => {
            // console.log(err)
            setCreateInvoiceLoading(false)
            setDeleteInvoiceLoading(false)
        })
    }

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            textTitle={inspectedData ? `Billing Report for ${inspectedData?.name}` : '-'}
            content={
                <div style={{ width: 950, paddingLeft: 24, paddingRight: 24, paddingBottom: 40, boxSizing: 'border-box' }}>
                    <Grid container spacing={2} style={{ flexGrow: 1 }}>
                        <Box
                            sx={{
                                width: '100%',
                                mt: 4
                            }}
                        >
                            <BillingReportList
                                loading={isLoading}
                                billingReports={billingReports}
                                client={inspectedData}
                                handleDeleteBillingReport={handleDeleteInvoiceByInvoiceId}
                            />

                            <Box
                                sx={{
                                    mt: 2,
                                    opacity: createInvoiceLoading ? 0.3 : 1,
                                    pointerEvents: createInvoiceLoading ? 'none' : 'auto',
                                    pointer: createInvoiceLoading ? 'disabled' : 'auto',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <TertiaryButton
                                    theme="primary"
                                    size="small"
                                    icon={<PlusIcon />}
                                    onClick={handleGenerateNewInvoice}
                                >
                                    {`Generate new report`}
                                </TertiaryButton>

                                {createInvoiceLoading && (
                                    <Box>
                                        <Spinner />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </div>
            }
        />
    )
}

export default BillingReportDialog