import React from "react";

import { ColorSAP, FONT_FAMILY, Typography } from "@commsult/ontego-ui";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Delete, Edit } from "@commsult/ontego-ui/dist/sap_icons";
import { LaunchOutlined } from "@material-ui/icons";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { ACTION_TYPE_UPDATE_CLIENT } from "../../../Constants/DashboardActionType";
import IconButton from "../../../ReusableComponents/IconButton/IconButton";
import Spinner from "../../../ReusableComponents/Spinner/Spinner";
import Tooltip from "../../../ReusableComponents/Tooltip/Tooltip";

const GRID_TEMPLATE_COLUMNS = `30px 180px 80px auto 100px`
const GRID_COLUMN_GAP = `8px`

const DetailBoxLong = props => {
    const { width, data, hasDelete, onDelete, hasEdit, onEdit, actionItem } = props

    return (
        <Box
            style={{
                width: width ?? '100%',
                borderRadius: '4px',
                border: `1px solid ${ColorSAP.neutral[20]}`,
                background: ColorSAP.neutral[10],
                position: 'relative',
                display: 'flex',
            }}
        >
            {data.map((d, index) => (
                <React.Fragment key={`detail-box-long-client-list-${index}`}>
                    <Box
                        sx={{
                            padding: '4px 8px',
                            boxSizing: 'border-box',
                            borderRight: index === data.length - 1 ? 'none' : `1px solid ${ColorSAP.neutral[20]}`
                        }}
                    >
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                            size={10}
                            color={ColorSAP.neutral[100]}
                        >
                            {d.label}
                        </Typography>
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                            size={12}
                            color={ColorSAP.neutral[100]}
                        >
                            {d.description}
                        </Typography>
                    </Box>
                </React.Fragment>
            ))}

            {actionItem && (
                actionItem
            )}

            {hasEdit && (
                <Edit
                    style={{
                        position: 'absolute',
                        right: 36,
                        top: 16,
                        color: ColorSAP.primary[50],
                        width: 16,
                        height: 16,
                        cursor: 'pointer'
                    }}
                    onClick={onEdit}
                />
            )}

            {hasDelete && (
                <Delete
                    style={{
                        position: 'absolute',
                        right: 12,
                        top: 16,
                        color: ColorSAP.danger[50],
                        width: 16,
                        height: 16,
                        cursor: 'pointer'
                    }}
                    onClick={onDelete}
                />
            )}
        </Box>
    )
}

const DetailBox = props => {
    const { label, description, hasDelete, onDelete, hasEdit, onEdit, actionItem } = props

    return (
        <Box
            style={{
                width: '100%',
                borderRadius: '4px',
                border: `1px solid ${ColorSAP.neutral[20]}`,
                background: ColorSAP.neutral[10],
                position: 'relative'
            }}
        >
            <Box
                sx={{
                    padding: '4px 8px',
                    boxSizing: 'border-box'
                }}
            >
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                    size={10}
                    color={ColorSAP.neutral[100]}
                >
                    {label}
                </Typography>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {description}
                </Typography>
            </Box>

            {actionItem && (
                actionItem
            )}

            {hasEdit && (
                <Edit
                    style={{
                        position: 'absolute',
                        right: 36,
                        top: 16,
                        color: ColorSAP.primary[50],
                        width: 16,
                        height: 16,
                        cursor: 'pointer'
                    }}
                    onClick={onEdit}
                />
            )}

            {hasDelete && (
                <Delete
                    style={{
                        position: 'absolute',
                        right: 12,
                        top: 16,
                        color: ColorSAP.danger[50],
                        width: 16,
                        height: 16,
                        cursor: 'pointer'
                    }}
                    onClick={onDelete}
                />
            )}
        </Box>
    )
}

const Header = () => {
    const { t } = useTranslation()

    return (
        <Box
            sx={{
                padding: '0px 12px',
                boxSizing: 'border-box',
                width: '100%',
                height: 40,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                background: ColorSAP.primary[20],
                display: 'grid',
                alignItems: 'center',
                gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
                gridColumnGap: GRID_COLUMN_GAP
            }}
        >
            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {`#`}
                </Typography>
            </Box>

            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {`${t(`manage.name`)} & ${t(`common.email`)}`}
                </Typography>
            </Box>

            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {`Ontego ID`}
                </Typography>
            </Box>

            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {t(`manage.billingStatus`)}
                </Typography>
            </Box>
        </Box>
    )
}

const ClientList = props => {
    const {
        clients,
        loading,
        handleOpenReportDialog,
        handleOpenBillingDialog,
        handleOpenClientDialog,
        handleOpenDeleteClientDialog
    } = props

    const { t } = useTranslation()

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 60,
        minHeight: 60
    })

    const virtualMessageRowRenderer = ({
        key, // Unique key within array of rows
        index, // Index of row within collection
        parent,
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style // Style object to be applied to row (to position it)
    }) => {
        return (
            <CellMeasurer
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
            >
                <div style={style} key={key}>
                    <Box
                        sx={{
                            padding: '0px 12px',
                            height: '60px',
                            display: 'grid',
                            alignItems: 'center',
                            gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
                            gridColumnGap: GRID_COLUMN_GAP,
                            borderBottom: `1px solid ${ColorSAP.neutral[20]}`,
                            background: index % 2 !== 0 ? ColorSAP.neutral[10] : ColorSAP.neutral[0],
                            transition: '0.15s all ease-in-out',
                            '&:hover': {
                                transition: '0.15s all ease-in-out',
                                background: ColorSAP.primary[10],
                                borderBottom: `1px solid ${ColorSAP.neutral[30]}`,
                                "& .client-actions": {
                                    opacity: 1
                                }
                            }
                        }}
                    >
                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                size={12}
                                color={ColorSAP.neutral[100]}
                            >
                                {index + 1}
                            </Typography>
                        </Box>

                        <Box>
                            <Tooltip
                                title={clients[index].name ?? '-'}
                                placement={'bottom'}
                            >
                                <Box
                                    sx={{
                                        width: 'fit-content',
                                        height: 'fit-content'
                                    }}
                                >
                                    <Typography
                                        fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                        size={12}
                                        color={ColorSAP.neutral[100]}
                                        style={{
                                            maxWidth: 165,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        {clients[index].name ?? '-'}
                                    </Typography>
                                </Box>
                            </Tooltip>

                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={10}
                                color={ColorSAP.neutral[80]}
                            >
                                {clients[index].email ?? '-'}
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Tooltip
                                    title={`Status`}
                                    placement={'bottom'}
                                >
                                    <Box
                                        sx={{
                                            width: 'fit-content',
                                            height: 'fit-content'
                                        }}
                                    >
                                        <Typography
                                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                            size={10}
                                            color={clients[index].hasPurchased ? ColorSAP.primary[70] : ColorSAP.neutral[50]}
                                        >
                                            {clients[index].hasPurchased ? `Kunden` : `Interessent`}
                                        </Typography>
                                    </Box>
                                </Tooltip>
                                <Tooltip
                                    title={`Rolle`}
                                    placement={'bottom'}
                                >
                                    <Box
                                        sx={{
                                            width: 'fit-content',
                                            height: 'fit-content',
                                            marginLeft: 6
                                        }}
                                    >
                                        <Typography
                                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                            size={10}
                                            color={ColorSAP.neutral[80]}
                                        >
                                            {`(${clients[index].roles ?? '-'})`}
                                        </Typography>
                                    </Box>
                                </Tooltip>
                            </Box>
                        </Box>

                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={12}
                                color={ColorSAP.neutral[100]}
                            >
                                {clients[index].ontegoid ?? '-'}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: 'grid',
                                alignItems: 'center',
                                gridTemplateColumns: `max-content 130px`,
                                gridColumnGap: `6px`,
                            }}
                        >
                            <DetailBoxLong
                                width={`fit-content`}
                                data={[
                                    {
                                        label: t('manage.nextBillingDate'),
                                        description: clients[index].nextBillingDate ?? '-'
                                    },
                                    {
                                        label: t('manage.billingType'),
                                        description: clients[index].billingType ?? '-'
                                    },
                                    {
                                        label: t('manage.billingMonthCycle'),
                                        description: clients[index].billingMonthCycle ?? '-'
                                    },
                                    {
                                        label: t('manage.billingPeriods'),
                                        description: clients[index].numberOfBillings ?? '0'
                                    }
                                ]}
                                actionItem={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            marginRight: 4
                                        }}
                                    >
                                        <IconButton
                                            textTooltip={`Open Billing Management Dialog`}
                                            color="primary"
                                            icon={<LaunchOutlined />}
                                            onClick={handleOpenBillingDialog(clients[index])}
                                        />
                                    </Box>
                                }
                            />

                            <DetailBox
                                label={t('manage.invoices')}
                                description={clients[index].numberOfInvoices}
                                actionItem={
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 4,
                                            right: 4
                                        }}
                                    >
                                        <IconButton
                                            textTooltip={`Open Billing Report Dialog`}
                                            color="primary"
                                            icon={<LaunchOutlined />}
                                            onClick={handleOpenReportDialog(clients[index])}
                                        />
                                    </Box>
                                }
                            />
                        </Box>

                        <Box
                            className="client-actions"
                            sx={{
                                opacity: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <IconButton
                                textTooltip={`Edit Client`}
                                color="primary"
                                icon={<Edit />}
                                onClick={handleOpenClientDialog(ACTION_TYPE_UPDATE_CLIENT, clients[index])}
                            />

                            {/* <IconButton
                                textTooltip={`Delete Client`}
                                rootSx={{
                                    marginLeft: 8
                                }}
                                color="danger"
                                icon={<Delete />}
                                onClick={handleOpenDeleteClientDialog(clients[index])}
                            /> */}
                        </Box>
                    </Box>
                </div>
            </CellMeasurer>
        )
    }

    return (
        <>
            <Box sx={{ marginTop: 16 }} />
            <Header />
            <Box
                sx={{
                    width: '100%',
                    height: 640,
                    background: ColorSAP.neutral[5]
                }}
            >
                {loading ? (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Spinner
                            style={{
                                width: 80,
                                height: 80,
                                color: ColorSAP.primary[70]
                            }}
                        />
                    </Box>
                ) : (
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                rowCount={clients.length}
                                width={width}
                                height={height}
                                deferredMeasurementCache={cache}
                                rowHeight={cache.rowHeight}
                                rowRenderer={virtualMessageRowRenderer}
                                overscanRowCount={3}
                            />
                        )}
                    </AutoSizer>
                )}
            </Box>
        </>
    )
}

export default ClientList