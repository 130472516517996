// Material-UI Styles
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Constants
import { ColorSAP } from "@commsult/ontego-ui";

export const useStyles = makeStyles((theme) =>
    createStyles({
        container__dark: {
            color: ColorSAP.neutral[0],
            borderBottom: `1.5px solid transparent`,
            "&:hover": {
                borderBottom: `1.5px solid ${ColorSAP.neutral[0]}`
            }
        },
        container__light: {
            color: ColorSAP.neutral[100],
            borderBottom: `1.5px solid transparent`,
            "&:hover": {
                borderBottom: `1.5px solid ${ColorSAP.neutral[100]}`
            }
        },
        container__primary: {
            color: `${ColorSAP.primary[70]} !important`,
            borderBottom: `1.5px solid transparent`,
            "&:hover": {
                borderBottom: `1.5px solid ${ColorSAP.primary[70]}`
            }
        },
        arrowContainer__dark: {
            color: ColorSAP.neutral[15],
        },
        arrowContainer__light: {
            color: ColorSAP.neutral[80],
        },
        arrowContainer__primary: {
            color: ColorSAP.primary[70],
        },
        container: {
            width: 'fit-content',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            "&::after": {
                content: "''",
                borderWidth: '0%',
                display: 'block',
                transition: '0.3s all ease-in-out'
            },
            "&:hover::after": {
                borderWidth: '100%',
            }
        },
        arrowContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        arrowContainer__small: {
            "& svg": {
                width: 16,
                height: 16
            }
        },
        arrowContainer__medium: {
            "& svg": {
                width: 22,
                height: 22
            }
        },
        arrowContainer__large: {
            "& svg": {
                width: 38,
                height: 38
            }
        }
    })
);