import axios from 'axios';
import { USER_API_BASE_BILLINGS_URL, USER_API_BASE_CLIENTS_URL, USER_API_BASE_CLIENTS_USERS_URL, USER_API_BASE_INVOICE_URL, USER_API_BASE_OVERVIEW_URL, getApiUrl } from '../../Constants/Api';

export class AdminUserService {
    getAllClients() {
        return axios.get(getApiUrl() + USER_API_BASE_CLIENTS_URL);
    }

    createNewClient(userData) {
        return axios.post(getApiUrl() + USER_API_BASE_CLIENTS_URL, { userData });
    }

    createNewClientUser(clientUser) {
        return axios.post(`${getApiUrl()}${USER_API_BASE_CLIENTS_USERS_URL}/createNewClientUser`, clientUser);
    }

    deleteClient(clientkey) {
        return axios.delete(`${getApiUrl()}${USER_API_BASE_CLIENTS_URL}/${clientkey}`);
    }

    updateClient(clientkey, userData) {
        return axios.put(`${getApiUrl()}${USER_API_BASE_CLIENTS_URL}/${clientkey}`, { userData });
    }

    updateClientBilling(clientKey, userData) {
        return axios.put(`${getApiUrl()}${USER_API_BASE_CLIENTS_URL}/billing/${clientKey}`, { userData });
    }

    getProspectiveClientsLoginCount(start, end) {
        return axios.get(getApiUrl() + USER_API_BASE_CLIENTS_URL + "/logs" + "/loginCount" + `?start=${start}&end=${end}`)
    }

    createNewBilling(clientKey, billingData) {
        return axios.post(`${getApiUrl()}${USER_API_BASE_BILLINGS_URL}/${clientKey}`, billingData)
    }

    updateBilling(billingId, billingData) {
        return axios.put(`${getApiUrl()}${USER_API_BASE_BILLINGS_URL}/id/${billingId}`, billingData)
    }

    deleteBilling(billingId) {
        return axios.delete(`${getApiUrl()}${USER_API_BASE_BILLINGS_URL}/id/${billingId}`)
    }

    generateReport(clientKey) {
        return axios.get(`${getApiUrl()}${USER_API_BASE_BILLINGS_URL}/report/${clientKey}`)
    }

    getInvoiceByClientKey(clientKey) {
        return axios.get(`${getApiUrl()}${USER_API_BASE_INVOICE_URL}/${clientKey}`)
    }

    generateNewInvoiceByClientKey(clientKey) {
        return axios.post(`${getApiUrl()}${USER_API_BASE_INVOICE_URL}/${clientKey}`)
    }

    deleteInvoice(invoiceId) {
        return axios.delete(`${getApiUrl()}${USER_API_BASE_INVOICE_URL}/${invoiceId}`)
    }

    getOverviewClientCount(ishaspurchased) {
        return axios.get(`${getApiUrl()}${USER_API_BASE_OVERVIEW_URL}/clients?ishaspurchased=${ishaspurchased}`)
    }

    getOverviewBlacklistedDeviceCount() {
        return axios.get(`${getApiUrl()}${USER_API_BASE_OVERVIEW_URL}/blacklistedDevice`)
    }

    getBillingsByClientKey(clientKey) {
        return axios.get(`${getApiUrl()}${USER_API_BASE_BILLINGS_URL}/${clientKey}`)
    }
}

export default new AdminUserService();