//export const USER_API_BASE_URL = "https://cockpit.ontego.de/api/";
export const USER_API_BASE_APPS_URL = "apps";
export const USER_API_BASE_DEVICES_URL = "devices";
export const USER_API_BASE_ROLES_URL = "roles";
export const USER_API_BASE_USERS_URL = "users";
export const USER_API_BASE_CLIENTS_URL = "clients";
export const USER_API_BASE_CLIENTS_USERS_URL = "clientUsers";
export const USER_API_BASE_COLLECTION_URL = "collection";
export const USER_API_BASE_FUNCTION_URL = "function";
export const USER_API_BASE_AUTH_URL = "auth";
export const USER_API_BASE_ONTEGO_URL = "ontegobuild";
export const USER_API_BASE_BLACKLISTED_DEVICE_URL = "blacklistedDevice";
export const USER_API_BASE_FUNCTION_NOTES_URL = "functionNotes";
export const USER_API_BASE_BILLINGS_URL = "billings";
export const USER_API_BASE_INVOICE_URL = "invoice";
export const USER_API_BASE_OVERVIEW_URL = "overview";

export const getApiUrl = () => {
    return window.REACT_APP_USER_API_BASE_URL;
}