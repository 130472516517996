export const LICENSE_TYPE = [
    {
        value: 'ONE_TIME_PURCHASE',
        label: 'One Time Purchase'
    },
    {
        value: 'SUBSCRIPTION',
        label: 'Subscription'
    }
]

export const BILLING_TYPE = [
    {
        value: 'PREPAID',
        label: 'Prepaid'
    },
    {
        value: 'POSTPAID',
        label: 'Postpaid'
    }
]

export const getLicenseTypeObjectFromValue = val => {
    return LICENSE_TYPE.filter(data => data.value === val)[0]
}

export const getBillingTypeObjectFromValue = val => {
    return BILLING_TYPE.filter(data => data.value === val)[0]
}