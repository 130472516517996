import {
    ColorSAP,
    Euro,
    Plus
} from '@commsult/ontego-ui';
import { Edit } from '@commsult/ontego-ui/dist/sap_icons';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App';
import { BILLING_TYPE, getBillingTypeObjectFromValue } from '../../Constants/Billing';
import { ACTION_TYPE_ADD_BILLING, ACTION_TYPE_UPDATE_BILLING } from '../../Constants/DashboardActionType';
import AdminUserService from '../../Pages/AdminDashboard/AdminUserService';
import Button from '../../ReusableComponents/Button/Button';
import Checkbox from '../../ReusableComponents/Checkbox/Checkbox';
import CustomDateRangePicker from '../../ReusableComponents/Datepicker/CustomDateRangePicker';
import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';
import Form from '../../ReusableComponents/Form/Form';
import Select from '../../ReusableComponents/Select/Select';
import Spinner from '../../ReusableComponents/Spinner/Spinner';
import TertiaryButton from '../../ReusableComponents/TertiaryButton/TertiaryButton';

const BillingPeriodDialog = props => {
    const { open, onClose, handleReloadData, actionType, inspectedData, inspectedBillingData } = props;
    const { t } = useTranslation();

    const {
        handleShowErrorSnackbar, handleShowSnackbar
    } = useContext(AppContext);

    const [billingType, setBillingType] = useState(BILLING_TYPE[0])
    const [periodStart, setPeriodStart] = useState(undefined)
    const [periodEnd, setPeriodEnd] = useState(undefined)
    const [isPeriodEndInfinity, setIsPeriodEndInfinity] = useState(false)
    const [baseFee, setBaseFee] = useState(395)
    const [includedDevice, setIncludedDevice] = useState(5)
    const [additionalDeviceFee, setAdditionalDeviceFee] = useState(39)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(false)
        if (actionType === ACTION_TYPE_UPDATE_BILLING) {
            setBillingType(getBillingTypeObjectFromValue(inspectedBillingData.billing_type))
            setPeriodStart(moment(inspectedBillingData.period_start))
            setPeriodEnd(moment(inspectedBillingData.period_end))
            setBaseFee(inspectedBillingData.billing_info.base_fee)
            setIncludedDevice(inspectedBillingData.billing_info.included_device)
            setAdditionalDeviceFee(inspectedBillingData.billing_info.additional_device_fee)
        } else if (actionType === ACTION_TYPE_ADD_BILLING) {
            setBillingType(BILLING_TYPE[0])
            setPeriodStart(undefined)
            setPeriodEnd(undefined)
            setIsPeriodEndInfinity(false)
            setBaseFee(395)
            setIncludedDevice(5)
            setAdditionalDeviceFee(39)
        }
    }, [open])

    useEffect(() => {
        if (isPeriodEndInfinity) {
            setPeriodEnd(moment().add(100, 'years'))
        }
        else {
            setPeriodEnd(moment(periodStart))
        }
    }, [isPeriodEndInfinity])

    const handleChangeBillingType = val => {
        setBillingType(val)
    }

    const handleChangePeriod = ({ startDate, endDate }) => {
        setPeriodStart(startDate)
        setPeriodEnd(endDate)
    }

    const handleChangeBaseFee = e => {
        setBaseFee(e.currentTarget.value)
    }

    const handleChangeIncludedDevice = e => {
        setIncludedDevice(e.currentTarget.value)
    }

    const handleChangeAdditionalDeviceFee = e => {
        setAdditionalDeviceFee(e.currentTarget.value)
    }

    const handleChangeIsPeriodEndInfinity = () => {
        setIsPeriodEndInfinity(!isPeriodEndInfinity)
    }

    const handleSubmit = e => {
        e.preventDefault()
        const hasError = errorValidation()

        setLoading(true)

        if (!hasError) {
            const formModel = {
                billing_type: billingType.value,
                period_start: moment(periodStart).format('DD-MM-YYYY'),
                period_end: moment(periodEnd).format('DD-MM-YYYY'),
                billing_info: {
                    base_fee: billingType.value === 'PREPAID' ? 0 : baseFee,
                    included_device: includedDevice,
                    additional_device_fee: additionalDeviceFee
                }
            }

            if (actionType === ACTION_TYPE_ADD_BILLING) {
                AdminUserService.createNewBilling(inspectedData.key, formModel).then(res => {
                    handleShowSnackbar({
                        message: t("manage.objectCreated", { object: `Billing Period ${formModel.billing_type} (${formModel.period_start} bis ${formModel.period_end} für Kunde ${inspectedData.name})` }),
                        autoHideDuration: 3000,
                        icon: <Plus />,
                        showButton: true,
                    })
                    setLoading(false)
                    handleReloadData()
                    handleClose()
                }).catch(err => {
                    handleShowErrorSnackbar(err);
                })
            }
            else if (actionType === ACTION_TYPE_UPDATE_BILLING) {
                AdminUserService.updateBilling(inspectedBillingData.id, formModel).then(res => {
                    handleShowSnackbar({
                        message: t("manage.objectEdited", { object: `Billing Period ${formModel.billing_type} (${formModel.period_start} bis ${formModel.period_end} für Kunde ${inspectedData.name})` }),
                        autoHideDuration: 3000,
                        icon: <Edit />,
                        showButton: true,
                    })
                    setLoading(false)
                    handleReloadData()
                    handleClose()
                }).catch(err => {
                    handleShowErrorSnackbar(err);
                })
            }
        }
        else {
            setLoading(false)
        }
    }

    const handleClose = () => {
        onClose()
    }

    const errorValidation = () => {
        let hasError = false

        return hasError;
    }

    const titleForm = {
        ADD_BILLING: `Add New Billing Period`,
        UPDATE_BILLING: `Update Billing Period`,
    }

    const buttonText = {
        ADD_BILLING: `Submit`,
        UPDATE_BILLING: t("manage.ok"),
    }

    return (
        <>
            <DialogBox
                open={open}
                handleClose={handleClose}
                textTitle={titleForm[actionType]}
                content={
                    <div style={{ width: 600, paddingLeft: 24, paddingRight: 24, paddingBottom: 40, boxSizing: 'border-box', transition: 'all 0.3s ease-in-out' }}>
                        <Box
                            sx={{
                                height: loading ? 250 : '100%',
                                width: '100%',
                                display: loading ? 'flex' : 'auto',
                                alignItems: loading ? 'center' : 'auto',
                                justifyContent: loading ? 'center' : 'auto',
                                marginTop: 24
                            }}
                        >
                            {loading ? (
                                <Spinner
                                    style={{
                                        width: 100,
                                        height: 100
                                    }}
                                />
                            ) : (
                                <Grid container spacing={0} style={{ flexGrow: 1 }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div
                                            style={{
                                                marginTop: 24
                                            }}
                                        >
                                            <Select
                                                label={t("manage.billingType")}
                                                placeholder={t("manage.billingType")}
                                                onSelect={handleChangeBillingType}
                                                value={billingType}
                                                item={BILLING_TYPE}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <div style={{ marginTop: 24 }}>
                                            <CustomDateRangePicker
                                                labelStart={`Period Start`}
                                                labelEnd={`Period End`}
                                                placeholderStart={`Select Starting Period`}
                                                placeholderEnd={`Select Ending Period`}
                                                start={moment(periodStart).format('DD.MM.YYYY')}
                                                end={moment(periodEnd).format('DD.MM.YYYY')}
                                                onChangeDate={handleChangePeriod}
                                            />
                                            <Checkbox
                                                name="types"
                                                label={`Continuous`}
                                                value={isPeriodEndInfinity}
                                                checked={isPeriodEndInfinity}
                                                style={{ marginTop: 12, marginLeft: '51%' }}
                                                onChange={handleChangeIsPeriodEndInfinity}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <div
                                            style={{
                                                marginTop: 24,
                                                display: 'grid',
                                                gridTemplateColumns: billingType.value === "POSTPAID" ? 'auto auto auto' : 'auto auto',
                                                gap: '12px'
                                            }}
                                        >
                                            {billingType.value === "POSTPAID" && (
                                                <Form
                                                    label={`Base Fee`}
                                                    placeholder={`495`}
                                                    required
                                                    value={baseFee}
                                                    onChange={handleChangeBaseFee}
                                                    // error={emailError}
                                                    // errorMessage={emailError && t(`manage.fieldRequired`)}
                                                    // tabIndex={1}
                                                    endAdornment={<Euro style={{ width: 20, height: 20, color: ColorSAP.neutral[60] }} />}
                                                />
                                            )}
                                            <Form
                                                label={`Included Device`}
                                                placeholder={`5`}
                                                required
                                                value={includedDevice}
                                                onChange={handleChangeIncludedDevice}
                                            // error={emailError}
                                            // errorMessage={emailError && t(`manage.fieldRequired`)}
                                            // tabIndex={1}
                                            />
                                            <Form
                                                label={`Additional Device Fee`}
                                                placeholder={`39`}
                                                required
                                                value={additionalDeviceFee}
                                                onChange={handleChangeAdditionalDeviceFee}
                                                // error={emailError}
                                                // errorMessage={emailError && t(`manage.fieldRequired`)}
                                                // tabIndex={1}
                                                endAdornment={<Euro style={{ width: 20, height: 20, color: ColorSAP.neutral[60] }} />}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item md={12}>
                                        <div style={{ marginTop: 24 }} />
                                    </Grid>

                                    <Grid item md={12}>
                                        <div tabIndex={3} style={{ marginTop: 24, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <TertiaryButton
                                                theme="light"
                                                size="small"
                                                onClick={handleClose}
                                            >
                                                {`Cancel`}
                                            </TertiaryButton>
                                            <Button
                                                type="primary"
                                                theme="dark"
                                                size="small"
                                                onClick={handleSubmit}
                                            >
                                                {buttonText[actionType]}
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </div>
                }
            />
        </>
    )
}

export default BillingPeriodDialog;