
import { Edit } from '@commsult/ontego-ui/dist/sap_icons';
import { Box, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../App';
import { BILLING_TYPE, getBillingTypeObjectFromValue, getLicenseTypeObjectFromValue, LICENSE_TYPE } from '../../../Constants/Billing';
import { ACTION_TYPE_ADD_BILLING } from '../../../Constants/DashboardActionType';
import PlusIcon from '../../../Icons/PlusIcon';
import AdminUserService from '../../../Pages/AdminDashboard/AdminUserService';
import Button from '../../../ReusableComponents/Button/Button';
import CustomDatePicker from '../../../ReusableComponents/Datepicker/CustomDatePicker';
import DialogBox from '../../../ReusableComponents/DialogBox/DialogBox';
import Form from '../../../ReusableComponents/Form/Form';
import Select from '../../../ReusableComponents/Select/Select';
import Spinner from '../../../ReusableComponents/Spinner/Spinner';
import TertiaryButton from '../../../ReusableComponents/TertiaryButton/TertiaryButton';
import BillingManagementList from './BillingManagementList';

const BillingManagementDialog = props => {
    const {
        handleShowErrorSnackbar,
        handleShowSnackbar
    } = useContext(AppContext)

    const { open, onClose, inspectedData, isReload, handleReloadData, handleOpenBillingPeriodDialog, handleCloseBillingPeriodDialog } = props

    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)

    const [createBillingLoading, setCreateBillingLoading] = useState(false)
    const [deleteBillingLoading, setDeleteBillingLoading] = useState(false)

    const [billings, setBillings] = useState(null)

    const [licenseType, setLicenseType] = useState(LICENSE_TYPE[0])
    const [billingType, setBillingType] = useState(BILLING_TYPE[0])
    const [nextBillingDate, setNextBillingDate] = useState(undefined)
    const [numberOfMonth, setNumberOfMonth] = useState(1)

    useEffect(() => {
        if (inspectedData?.licenseType) {
            setLicenseType(getLicenseTypeObjectFromValue(inspectedData.licenseType))
        }

        if (inspectedData?.billingType) {
            setBillingType(getBillingTypeObjectFromValue(inspectedData.billingType))
        }

        if (inspectedData?.nextBillingDate) {
            setNextBillingDate(moment(inspectedData?.nextBillingDate, 'DD.MM.YYYY'))
        }

        if (inspectedData?.billingMonthCycle) {
            setNumberOfMonth(inspectedData?.billingMonthCycle)
        }
    }, [open])

    const handleChangeLicenseType = val => {
        setLicenseType(val)
    }

    const handleChangeBillingType = val => {
        setBillingType(val)
    }

    const handleChangeNextBillingDate = val => {
        setNextBillingDate(moment(val))
    }

    const handleChangeNumberOfMonth = e => {
        let val = e.target.value

        if (val < 0) {
            val = 1
        }

        setNumberOfMonth(val)
    }

    const errorValidation = () => {
        return false
    }

    const handleSubmit = e => {
        e.preventDefault();
        const hasError = errorValidation();


        if (!hasError) {
            const formModel = {
                licenseType: licenseType.value,
                billingType: billingType.value,
                nextBillingDate: moment(nextBillingDate).format('YYYY-MM-DD'),
                numberOfMonth: numberOfMonth
            };

            AdminUserService.updateClientBilling(inspectedData.key, formModel).then(res => {
                handleReloadData();

                handleShowSnackbar({
                    message: t("manage.objectEdited", { object: inspectedData.name }),
                    autoHideDuration: 3000,
                    icon: <Edit />,
                    showButton: true,
                });
                onClose()
            }).catch(err => {
                handleShowErrorSnackbar(err)
            })
        }
    }

    const fetchBillingsByClientKey = () => {
        setIsLoading(true)
        AdminUserService.getBillingsByClientKey(inspectedData?.key).then(res => {
            setBillings(res.data)
            setIsLoading(false)
            setCreateBillingLoading(false)
        }).catch(err => {
            setIsLoading(false)
            setCreateBillingLoading(false)
        })
    }

    useEffect(() => {
        if (open) {
            fetchBillingsByClientKey()
        }
    }, [open, isReload])

    const handleDeleteBillingPeriod = id => e => {
        e.stopPropagation()

        AdminUserService.deleteBilling(id).then(res => {
            handleReloadData()
        })
    }

    return (
        <DialogBox
            open={open}
            handleClose={onClose}
            textTitle={inspectedData ? `Billing Management for ${inspectedData?.name}` : '-'}
            content={
                <div style={{ width: 950, paddingLeft: 24, paddingRight: 24, paddingBottom: 40, boxSizing: 'border-box' }}>
                    <Grid container spacing={2} style={{ flexGrow: 1 }}>
                        <Box
                            sx={{
                                width: '100%',
                                mt: 4
                            }}
                        >
                            <Box
                                sx={{
                                    marginBottom: 24
                                }}
                            >
                                <Grid container spacing={2} style={{ flexGrow: 1 }}>
                                    <Grid item xs={6} sm={6} md={6}>
                                        <Select
                                            label={t("manage.licenseType")}
                                            placeholder="License Type"
                                            onSelect={handleChangeLicenseType}
                                            value={licenseType}
                                            item={LICENSE_TYPE}
                                        />
                                    </Grid>

                                    <Grid item xs={6} sm={6} md={6}>
                                        <Box
                                            sx={{
                                                visibility: licenseType.value === 'SUBSCRIPTION' ? 'visible' : 'hidden'
                                            }}
                                        >
                                            <Select
                                                label={t("manage.billingType")}
                                                placeholder="Billing Type"
                                                onSelect={handleChangeBillingType}
                                                value={billingType}
                                                item={BILLING_TYPE}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={6} sm={6} md={6}>
                                        <CustomDatePicker
                                            id={`client-billing-dialog-datepicker`}
                                            label={t("manage.nextBillingDate")}
                                            placeholder={t("manage.nextBillingDate")}
                                            value={nextBillingDate}
                                            onChangeDate={handleChangeNextBillingDate}
                                        />
                                    </Grid>

                                    <Grid item xs={6} sm={6} md={6}>
                                        <Form
                                            label={t("manage.billingMonthCycle")}
                                            placeholder="Number of Month"
                                            required
                                            number
                                            value={numberOfMonth}
                                            onChange={handleChangeNumberOfMonth}
                                        // value={minimumDevice}
                                        // onChange={handleChangeMinimumDevice}
                                        // error={emailError}
                                        // errorMessage={emailError && t(`manage.fieldRequired`)}
                                        // tabIndex={1}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <Button
                                            type="primary"
                                            theme="dark"
                                            size="small"
                                            onClick={handleSubmit}
                                        >
                                            {`Update Billing Information`}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>

                            <BillingManagementList
                                handleOpenBillingPeriodDialog={handleOpenBillingPeriodDialog}
                                handleDeleteBillingPeriod={handleDeleteBillingPeriod}
                                loading={isLoading}
                                client={inspectedData}
                                billings={billings}
                            />

                            <Box
                                sx={{
                                    mt: 2,
                                    opacity: createBillingLoading ? 0.3 : 1,
                                    pointerEvents: createBillingLoading ? 'none' : 'auto',
                                    pointer: createBillingLoading ? 'disabled' : 'auto',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <TertiaryButton
                                    theme="primary"
                                    size="small"
                                    icon={<PlusIcon />}
                                    onClick={handleOpenBillingPeriodDialog(ACTION_TYPE_ADD_BILLING, inspectedData, null)}
                                >
                                    {`Add new billing period`}
                                </TertiaryButton>

                                {createBillingLoading && (
                                    <Box>
                                        <Spinner />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </div>
            }
        />
    )
}

export default BillingManagementDialog