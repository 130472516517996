import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootWrapperContainer } from '../../App';
import { ACTION_TYPE_ADD } from '../../Constants/DashboardActionType';
import BillingManagementDialog from '../../Dialogs/AdminDashboard/BillingManagement/BillingManagementDialog';
import BillingPeriodDialog from '../../Dialogs/AdminDashboard/BillingPeriodDialog';
import BillingReportDialog from '../../Dialogs/AdminDashboard/BillingReport/BillingReportDialog';
import ClientDialog from '../../Dialogs/AdminDashboard/ClientDialog';
import ClientListWidget from './ClientList/ClientListWidget';
import LoginCountListWidget from './LoginCountList/LoginCountListWidget';
import OverviewWidget from './Overview/OverviewWidget';

const AdminDashboard = () => {
    const [inspectedData, setInspectedData] = useState(null)
    const [inspectedBillingData, setInspectedBillingData] = useState(null)
    const [addClientDialogOpen, setAddClientDialogOpen] = useState(false)
    const [addBillingDialogOpen, setAddBillingDialogOpen] = useState(false)

    const [reportDialogOpen, setReportDialogOpen] = useState(false)
    const [billingDialogOpen, setBillingDialogOpen] = useState(false)

    const [isReload, setIsReload] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null)
    const [actionType, setActionType] = useState(ACTION_TYPE_ADD)

    const { t } = useTranslation();

    const handleReloadData = () => setIsReload(!isReload)

    const handleClickShowMoreIcon = e => {
        e.stopPropagation()

        if (!anchorEl) {
            setAnchorEl(e.currentTarget)
        }
    }

    const handleOpenClientDialog = (type, data) => e => {
        e.stopPropagation()
        setInspectedData(data)

        setAddClientDialogOpen(true)
        setActionType(type)
    }

    const handleCloseClientDialog = () =>  setAddClientDialogOpen(false)

    const handleOpenBillingPeriodDialog = (type, data, billingData) => e => {
        e.stopPropagation()

        setInspectedData(data)
        setInspectedBillingData(billingData)

        setAddBillingDialogOpen(true)
        setActionType(type)
    }

    const handleCloseBillingPeriodDialog = () => setAddBillingDialogOpen(false)

    const handleOpenReportDialog = data => e => {
        e.stopPropagation()
        setInspectedData(data)

        setReportDialogOpen(true)
    }

    const handleCloseReportDialog = () => setReportDialogOpen(false)

    const handleOpenBillingManagementDialog = data => e => {
        e.stopPropagation()
        setInspectedData(data)

        setBillingDialogOpen(true)
    }

    const handleCloseBillingManagementDialog = () => setBillingDialogOpen(false)

    return (
        <>
            <RootWrapperContainer
                rootStyle={{
                    height: 'auto'
                }}
                childWrapperStyle={{
                    width: '100%',
                    maxWidth: '100%',
                    paddingLeft: '2%',
                    paddingRight: '2%'
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OverviewWidget />
                    </Grid>

                    {/* <Grid item xs={12}>
                        <DeviceCountGraphWidget />
                    </Grid> */}

                    <Grid item xs={12}>
                        <LoginCountListWidget />
                    </Grid>

                    <Grid item xs={12}>
                        <ClientListWidget
                            inspectedData={inspectedData}
                            setInspectedData={setInspectedData}
                            isReload={isReload}
                            setIsReload={setIsReload}
                            handleOpenClientDialog={handleOpenClientDialog}
                            handleOpenReportDialog={handleOpenReportDialog}
                            handleOpenBillingDialog={handleOpenBillingManagementDialog}
                            handleClickShowMoreIcon={handleClickShowMoreIcon}
                        />
                    </Grid>
                </Grid>
            </RootWrapperContainer>

            <ClientDialog
                isReload={isReload}
                handleReloadData={handleReloadData}
                open={addClientDialogOpen}
                onClose={handleCloseClientDialog}
                actionType={actionType}
                inspectedData={inspectedData}
            />

            <BillingPeriodDialog
                handleReloadData={handleReloadData}
                open={addBillingDialogOpen}
                onClose={handleCloseBillingPeriodDialog}
                actionType={actionType}
                inspectedData={inspectedData}
                inspectedBillingData={inspectedBillingData}
            />

            <BillingReportDialog
                open={reportDialogOpen}
                onClose={handleCloseReportDialog}
                inspectedData={inspectedData}
            />

            <BillingManagementDialog
                isReload={isReload}
                handleReloadData={handleReloadData}
                open={billingDialogOpen}
                onClose={handleCloseBillingManagementDialog}
                inspectedData={inspectedData}
                handleOpenBillingPeriodDialog={handleOpenBillingPeriodDialog}
                handleCloseBillingPeriodDialog={handleCloseBillingPeriodDialog}
            />
        </>
    );
}

export default AdminDashboard
