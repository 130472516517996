import React, { useEffect, useRef, useState } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';


import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';


import { NavigateBefore, NavigateNext } from '@material-ui/icons';

import moment from 'moment';

import { ColorSAP, FONT_FAMILY } from '@commsult/ontego-ui';
import classNames from 'classnames';
import Button from '../Button/Button';
import Form from '../Form/Form';
import TertiaryButton from '../TertiaryButton/TertiaryButton';

const useStyles = makeStyles({
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0
        },
        "100%": {
            opacity: 1
        }
    },
    "@keyframes zoomIn": {
        "0%": {
            transform: 'scale(0, 0)'
        },
        "100%": {
            transform: 'scale(1, 1)'
        }
    },
    dateGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, minmax(0, 1fr))',
        gridTemplateRows: 'auto',
        rowGap: 6,
        maxWidth: 250,
        maxHeight: 350
    },
    dateGridItem: {
        position: 'relative',
        border: '2px solid transparent',
        background: 'none',
        width: 32,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dateGridItemSelectorStart: {
        backgroundColor: 'transparent',
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%'
        // borderRadius: '30%'
    },
    dateGridItemSelectorStartTransparent: {
        backgroundColor: 'transparent',
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%'
        // borderRadius: '30%'
    },
    dateGridItemSelectorStartBackground: {
        backgroundColor: ColorSAP.primary[70],
        border: `2px solid ${ColorSAP.primary[70]}`,
        borderRadius: '50%',
        position: 'absolute',
        top: -2,
        left: -2,
        width: 32,
        height: 32,
        animation: `$zoomIn 120ms ease-in-out`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2
    },
    dateGridItemSelectorEnd: {
        backgroundColor: ColorSAP.primary[10],
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%'
    },
    dateGridItemSelectorEndBackground: {
        backgroundColor: '#fff',
        border: `2px solid ${ColorSAP.primary[70]}`,
        borderRadius: '50%',
        position: 'absolute',
        top: -2,
        left: -2,
        width: 32,
        height: 32,
        animation: `$zoomIn 120ms ease-in-out`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2
    },
    dateGridItemSelectorBetween: {
        backgroundColor: ColorSAP.primary[10],
        border: `2px solid ${ColorSAP.primary[10]}`
    },
    navigationBefore: {
        position: 'absolute',
        left: 18,
        top: 16,
        opacity: 1,
        animation: `$fadeIn 300ms ease-in-out`,
    },
    navigationNext: {
        position: 'absolute',
        right: 18,
        top: 16,
        opacity: 1,
        animation: `$fadeIn 300ms ease-in-out`
    }
})

const CustomPaper = withStyles({
    root: {
        borderRadius: 8,
        boxShadow: `0px 2px 29px 0px rgba(0, 0, 0, 0.12)`,
        zIndex: 9999999
    }
})(Paper)

const GLOBAL_BOX_SIZE = 30
const GLOBAL_FONT_SIZE = 13
const GLOBAL_PADDING = '10px 0px'

const DAYS_HEADER = [
    { dayName: 'Sunday', shorthand: 'S' },
    { dayName: 'Monday', shorthand: 'M' },
    { dayName: 'Tuesday', shorthand: 'D' },
    { dayName: 'Wednesday', shorthand: 'M' },
    { dayName: 'Thursday', shorthand: 'D' },
    { dayName: 'Friday', shorthand: 'F' },
    { dayName: 'Saturday', shorthand: 'S' }
]

const formatDoubleDigitDate = date => {
    if (date.length === 1) {
        return `0${date}`
    }
    else if (date.length === 2) {
        return date
    }
}

const calculateDifferenceFromStart = day => {
    let diff = 0

    switch (day) {
        case 'Sunday':
            diff = 0
            break
        case 'Monday':
            diff = 1
            break
        case 'Tuesday':
            diff = 2
            break
        case 'Wednesday':
            diff = 3
            break
        case 'Thursday':
            diff = 4
            break
        case 'Friday':
            diff = 5
            break
        case 'Saturday':
            diff = 6
            break
    }

    return diff
}

const calculateDifferenceFromEnd = day => {
    let diff = 0

    switch (day) {
        case 'Sunday':
            diff = 6
            break
        case 'Monday':
            diff = 5
            break
        case 'Tuesday':
            diff = 4
            break
        case 'Wednesday':
            diff = 3
            break
        case 'Thursday':
            diff = 2
            break
        case 'Friday':
            diff = 1
            break
        case 'Saturday':
            diff = 0
            break
    }

    return diff
}

const MonthTitle = props => {
    const { children } = props

    return (
        <div style={{ paddingTop: 15, paddingBottom: 20 }}>
            <Typography
                variant="h1"
                style={{ fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD, fontSize: 16, color: ColorSAP.neutral[100] }}
            >
                <b>{children}</b>
            </Typography>
        </div>
    )
}

const SelectorBackground = props => {
    const { children, classname, color } = props
    return (
        <div className={classname}>
            <Typography
                variant="h1"
                style={{
                    fontFamily: "Inter, sans-serif",
                    fontSize: GLOBAL_FONT_SIZE,
                    color: color
                }}
            >
                {children}
            </Typography>
        </div>
    )
}

const DateText = props => {
    const { children, color } = props
    return (
        <div style={{ width: GLOBAL_BOX_SIZE, height: GLOBAL_BOX_SIZE, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography
                variant="h1"
                style={{
                    fontWeight: 400,
                    fontFamily: "Inter, sans-serif",
                    fontSize: GLOBAL_FONT_SIZE,
                    color: color
                }}
            >
                {children}
            </Typography>
        </div>
    )
}

const TodayIndicator = props => {
    const { color } = props
    return (
        <div style={{ position: 'absolute', bottom: 4, left: 14, width: 4, height: 4, backgroundColor: color, borderRadius: '50%', zIndex: 100 }} />
    )
}

const DateLists = props => {
    const classes = useStyles(props)
    const { data, dateStart, min, max, onDateClick } = props

    return (
        <>
            {data.days.map((day, index) => {
                const isDisabled = []

                if (min !== null) {
                    isDisabled.push(moment(day.value).isBefore(moment(min).format('YYYY-MM-DD')))
                }

                if (max !== null) {
                    isDisabled.push(moment(day.value).isAfter(moment(max).format('YYYY-MM-DD')))
                }

                const isToday = day.value === moment().format('YYYY-MM-DD')

                const isCheckIn = moment(day.value).isSame(moment(dateStart))

                const selectorClasses = classNames({
                    [classes.dateGridItem]: true,
                    [classes.dateGridItemSelectorStart]: isCheckIn,
                    [classes.dateGridItemSelectorStartTransparent]: isCheckIn,
                })

                const dateText = day.date ? (day.date[0] === '0' ? day.date[1] : day.date) : ``

                return (
                    <div
                        key={`${index}-${day.value}`}
                        className={selectorClasses}
                        style={{
                            cursor: isDisabled.includes(true) ? 'not-allowed' : 'pointer',
                            // opacity: isDisabled.includes(true) ? 0.3 : 1,
                            userSelect: isDisabled.includes(true) ? 'none' : 'auto',
                        }}
                        onClick={!isDisabled.includes(true) ? onDateClick(day.value) : undefined}
                    >
                        {isCheckIn && (
                            <SelectorBackground
                                classname={classes.dateGridItemSelectorStartBackground}
                                color={`#FFF`}
                            >
                                {dateText}
                            </SelectorBackground>
                        )}
                        <DateText
                            color={isDisabled.includes(true) ? ColorSAP.neutral[30] : (isToday ? ColorSAP.primary[100] : (isCheckIn ? '#FFF' : ColorSAP.neutral[100]))}
                        >
                            {dateText}
                        </DateText>
                        {isToday && (
                            <TodayIndicator
                                color={isCheckIn ? '#FFF' : ColorSAP.primary[100]}
                            />
                        )}
                    </div>
                )
            })}
        </>
    )
}

const DesktopDatepicker = props => {
    const classes = useStyles()

    const { datepickerData, dateStart, min, max, onDateClick, handleChangeNextMonth, handleChangePreviousMonth, onReset, onClose } = props

    const scrollRef = useRef(null)

    const handleNavigateNextIndex = () => handleChangeNextMonth()

    const handleNavigateBeforeIndex = () => handleChangePreviousMonth()

    return (
        <div style={{ position: 'relative', backgroundColor: '#FFF' }}>
            <div
                ref={scrollRef}
                id="datepicker-desktop-container"
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    width: 280,
                    height: 340,
                    overflowX: 'scroll',
                    scrollBehavior: 'smooth',
                    scrollSnapType: 'x mandatory',
                    msScrollSnapPointsX: 'repeat(280px)'
                }}
            >
                {datepickerData && datepickerData.map(data => {
                    return (
                        <div
                            key={`${data.month}-${data.year}`}
                            style={{ float: 'left', width: 280, textAlign: 'center', textAlign: '-webkit-center', boxSizing: 'border-box', padding: GLOBAL_PADDING, scrollSnapAlign: 'start' }}
                        >
                            <MonthTitle>
                                {`${data.monthLabel} ${data.year}`}
                            </MonthTitle>
                            <div className={classes.dateGrid}>
                                {DAYS_HEADER.map(dayObj => (
                                    <div key={dayObj.shorthand}>
                                        <div style={{ width: GLOBAL_BOX_SIZE, height: GLOBAL_BOX_SIZE, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography
                                                variant="h1"
                                                style={{ fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_MEDIUM, fontSize: GLOBAL_FONT_SIZE, color: ColorSAP.neutral[100] }}
                                            >
                                                {dayObj.shorthand}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                                <DateLists
                                    data={data}
                                    dateStart={dateStart}
                                    onDateClick={onDateClick}
                                    min={min}
                                    max={max}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
            <Divider />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', boxSizing: 'border-box', padding: 12 }}>
                <div style={{ marginRight: '24px' }}>
                    <TertiaryButton
                        theme="light"
                        size="small"
                        onClick={onReset}
                        noIcon
                    >
                        {`Reset`}
                    </TertiaryButton>
                </div>
                <Button
                    type="primary"
                    theme="dark"
                    size="small"
                    onClick={onClose}
                >
                    {`Submit`}
                </Button>
            </div>

            <div
                className={classes.navigationBefore}
                onClick={handleNavigateBeforeIndex}
            >
                <Fab size="small" style={{ backgroundColor: ColorSAP.neutral[0], boxShadow: 'none' }}>
                    <NavigateBefore style={{ color: ColorSAP.neutral[90] }} />
                </Fab>
            </div>


            <div
                className={classes.navigationNext}
                onClick={handleNavigateNextIndex}
            >
                <Fab size="small" style={{ backgroundColor: ColorSAP.neutral[0], boxShadow: 'none' }}>
                    <NavigateNext style={{ color: ColorSAP.neutral[90] }} />
                </Fab>
            </div>

        </div>
    )
}

const CustomDatePicker = props => {
    const { id, label, placeholder, value, min, max, onChangeDate } = props

    const [anchorEl, setAnchorEl] = useState(null)

    const [dateStart, setDateStart] = useState(moment())
    const [tempDateStart, setTempDateStart] = useState(moment())

    const [datepickerData, setDatepickerData] = useState(null)

    const [selectedMonth, setSelectedMonth] = useState(moment())

    const handleClickDateInput = id => e => {
        const anchor = document.getElementById(id)

        if (anchor) {
            setAnchorEl(anchor)
        }
    }

    const handleClosePopover = () => {
        setAnchorEl(null)
    }

    const handleSubmitPopover = () => {
        const start = dateStart ? moment(dateStart) : moment();

        setDateStart(start)
        setTempDateStart(start)

        onChangeDate(start);
        setAnchorEl(null)
    }

    const handleClickDate = date => () => {
        setDateStart(moment(date))
        onChangeDate(moment(date));
    }

    const handleChangeNextMonth = () => {
        setSelectedMonth(moment(selectedMonth).add(1, 'M'))
    }

    const handleChangePreviousMonth = () => {
        setSelectedMonth(moment(selectedMonth).subtract(1, 'M'))
    }

    const handleResetDate = () => {
        setDateStart(moment())
    }

    useEffect(() => {
        if(value) {
            setDateStart(moment(value))
            setTempDateStart(moment(value))
        }
    }, [])

    useEffect(() => {
        const clonedSelectedMonth = moment(selectedMonth).clone()
        const data = []

        let dateObj = null
        let day = null
        let diff = 0

        for (let monthIndex = 0; monthIndex <= 0; monthIndex++) {
            dateObj = clonedSelectedMonth.add(monthIndex, 'M')
            data.push({
                monthLabel: dateObj.format('MMMM'),
                year: dateObj.format('YYYY'),
                month: dateObj.format('MM'),
                daysInMonth: dateObj.daysInMonth(),
                days: []
            })

            for (let date = 0; date < data[monthIndex].daysInMonth; date++) {
                day = moment(`${data[monthIndex].year}-${data[monthIndex].month}-${formatDoubleDigitDate(`${date + 1}`)}`).format('dddd')

                if (date === 0) {
                    diff = calculateDifferenceFromStart(day)
                    if (diff !== 0) {
                        for (let temp = 0; temp < diff; temp++) {
                            data[monthIndex].days.push({
                                date: null,
                                dayName: null,
                                value: null
                            })
                        }
                    }
                }

                data[monthIndex].days.push({
                    date: formatDoubleDigitDate(`${date + 1}`),
                    dayName: day,
                    value: `${data[monthIndex].year}-${data[monthIndex].month}-${formatDoubleDigitDate(`${date + 1}`)}`
                })

                if (date === data[monthIndex].daysInMonth - 1) {
                    diff = calculateDifferenceFromEnd(day)
                    if (diff !== 0) {
                        for (let temp = 0; temp < diff; temp++) {
                            data[monthIndex].days.push({
                                date: null,
                                dayName: null,
                                value: null
                            })
                        }
                    }
                }
            }
        }

        setDatepickerData(data)
    }, [selectedMonth])

    return (
        <div>
            <div style={{ width: '100%' }}>
                <Form
                    id={id}
                    label={label}
                    placeholder={placeholder}
                    value={moment(value).isValid() ? moment(value).format('DD.MM.YYYY') : null}
                    // required
                    // value={email}
                    // onChange={handleChangeEmail}
                    // error={emailError}
                    // errorMessage={emailError && t(`manage.fieldRequired`)}
                    // tabIndex={1}
                    onClick={handleClickDateInput(id)}
                />
            </div>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                style={{ marginTop: 6 }}
                PaperProps={{
                    component: CustomPaper
                }}
            >
                <DesktopDatepicker
                    datepickerData={datepickerData}
                    onDateClick={handleClickDate}
                    handleChangeNextMonth={handleChangeNextMonth}
                    handleChangePreviousMonth={handleChangePreviousMonth}
                    dateStart={dateStart}
                    min={min}
                    max={max}
                    onClose={handleSubmitPopover}
                    onReset={handleResetDate}
                />
            </Popover>
        </div>
    )
}

CustomDatePicker.defaultProps = {
    min: moment(),
    max: null,
    onChangeDate: ({ startDate, endDate }) => { console.log(`${startDate} - ${endDate}`) }
}

export default CustomDatePicker