import { FUNCTION_TYPE } from '@commsult/ontego-ui';
import { useEffect, useState } from 'react';

import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';

export const capitalizeFirstLetter = string => {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    else {
        return '-'
    }
}

export const convertJSONtoEditorState = notes => {
    if (typeof notes === 'string') {
        let parsedJSON;
        let contentState;
        let editorState;

        parsedJSON = JSON.parse(notes)
        contentState = convertFromRaw(parsedJSON)
        editorState = EditorState.createWithContent(contentState)

        return editorState
    }
}

export const convertUpAndRunningNotesToJSON = (upAndRunning) => {
    const newUpAndRunning = { ...upAndRunning }

    Object.values(newUpAndRunning).forEach(unr => {
        if (typeof unr.notes === 'object') {
            unr.notes = JSON.stringify(convertToRaw(unr.notes.getCurrentContent()))
        }
    })

    return newUpAndRunning
}

export const convertUpAndRunningNotesToEditorState = (upAndRunning) => {
    const newUpAndRunning = { ...upAndRunning }

    let parsedJSON;
    let contentState;
    let editorState;

    Object.values(newUpAndRunning).forEach(unr => {
        if (typeof unr.notes === 'string') {
            parsedJSON = JSON.parse(unr.notes)
            contentState = convertFromRaw(parsedJSON)
            editorState = EditorState.createWithContent(contentState)

            unr.notes = editorState
        }
    })

    return newUpAndRunning
}


export const convertNotesToJSON = (collection) => {
    const newCollection = [...collection]

    newCollection.forEach(collection => {
        if (typeof collection.notes === 'object') {
            collection.notes = JSON.stringify(convertToRaw(collection.notes.getCurrentContent()))
        }
        collection.processes.forEach(process => {
            if (typeof process.notes === 'object') {
                process.notes = JSON.stringify(convertToRaw(process.notes.getCurrentContent()))
            }
        })
    })

    return newCollection
}

export const convertNotesToEditorState = (collection) => {
    const newCollection = [...collection]

    let parsedJSON;
    let contentState;
    let editorState;

    newCollection.forEach(collection => {
        if (typeof collection.notes === 'string') {
            parsedJSON = JSON.parse(collection.notes)
            contentState = convertFromRaw(parsedJSON)
            editorState = EditorState.createWithContent(contentState)

            collection.notes = editorState
        }
        collection.processes.forEach(process => {
            if (typeof process.notes === 'string') {
                parsedJSON = JSON.parse(process.notes)
                contentState = convertFromRaw(parsedJSON)
                editorState = EditorState.createWithContent(contentState)

                process.notes = editorState
            }
        })
    })

    return newCollection
}

export const usePersistedState = (key, defaultValue) => {
    const [state, setState] = useState(() => JSON.parse(localStorage.getItem(key)) || defaultValue)
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state))
    }, [key, state])
    return [state, setState]
}

export const isProcessAlreadyAddedToCollection = (collection, process) => {
    let isAlreadyAdded = false

    collection.forEach(c => {
        c.processes.forEach(p => {
            if (p.name === process.name) {
                isAlreadyAdded = true
            }
        })
    })

    return isAlreadyAdded
}

export const isProcessTypeAlreadyAddedToCollection = (collection, process) => {
    let isAlreadyAdded = false

    collection.forEach(c => {
        c.processes.forEach(p => {
            if (p.name === process.name) {
                if (JSON.stringify(p.type) === JSON.stringify(process.dataType[process.activeIndex].type)) {
                    // console.log(p)
                    // console.log(process.dataType[process.activeIndex])
                    isAlreadyAdded = true
                }
            }
        })
    })

    return isAlreadyAdded
}

export const isProcessDataTypeAlreadyAddedToCollection = (collection, dataType) => {
    let isAlreadyAdded = false

    collection.forEach(c => {
        c.processes.forEach(p => {
            if (p.systemID === dataType.systemID) {
                if (JSON.stringify(p.type) === JSON.stringify(dataType.type)) {
                    // console.log(p)
                    // console.log(process.dataType[process.activeIndex])
                    isAlreadyAdded = true
                }
            }
        })
    })

    return isAlreadyAdded
}

export const timeout = ms => {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export const calculateCollectionSize = collection => {
    let counter = 0

    collection.forEach(c => {
        if (c.processes.length === 0) {
            counter++
        }
        else {
            counter += c.processes.length
        }
    })

    return counter
}

export const formatCollectionForEmail = (functions, upAndRunnings) => {
    console.log(functions)
    console.log(upAndRunnings)

    let collectionEmailFormat = ``

    const getUpAndRunningObj = id => {
        return upAndRunnings[id]
    }

    Object.values(upAndRunnings).forEach(obj => {
        collectionEmailFormat += `<li style="margin: 6px"><b>${obj.name} - ${obj.selectedTimeline}days</b><span style="font-size: 10px; margin-left: 12px">notes: ${obj.notes}</span></li><ul>`
        functions.forEach(c => {
            if (c.upAndRunningId === obj.id) {
                /** FUNCTION SELECTED */
                if (c.processes.length === 0) {
                    collectionEmailFormat += `<li style="margin: 6px"><b>${c.name}</b><span style="font-size: 10px; margin-left: 12px">notes: ${c.notes}</span></li>`
                }
                /** PROCESS SELECTED */
                else {
                    collectionEmailFormat += `<li style="margin: 6px"><b>${c.name}</b><span style="font-size: 10px; margin-left: 12px">notes: ${c.notes}</span></li><ul>`
                    c.processes.forEach((p, index) => {
                        if (p.type.length === 0) {
                            collectionEmailFormat += `<li style="margin: 6px">${p.name}</li>`
                        }
                        else {
                            collectionEmailFormat += `<li style="margin: 6px"><div style="display: flex; align-items: center">${p.name}`
                            if (p.type.includes(FUNCTION_TYPE.MM)) {
                                collectionEmailFormat += `<div style="border-radius: 13px; margin-left: 12px; box-sizing: border-box; padding: 0px 8px 0px 8px; background-color: #8FCFF6"><b style="font-size: 12px; color: #00527F">MM</b></div>`
                            }
                            if (p.type.includes(FUNCTION_TYPE.SD)) {
                                collectionEmailFormat += `<div style="border-radius: 13px; margin-left: 12px; box-sizing: border-box; padding: 0px 8px 0px 8px; background-color: #D0D0D0"><b style="font-size: 12px; color: #383D47">SD</b></div>`
                            }
                            if (p.type.includes(FUNCTION_TYPE.PP)) {
                                collectionEmailFormat += `<div style="border-radius: 13px; margin-left: 12px; box-sizing: border-box; padding: 0px 8px 0px 8px; background-color: #FFBFBD"><b style="font-size: 12px; color: #B30D05">PP</b></div>`
                            }
                            if (p.type.includes(FUNCTION_TYPE.WM)) {
                                collectionEmailFormat += `<div style="border-radius: 13px; margin-left: 12px; box-sizing: border-box; padding: 0px 8px 0px 8px; background-color: #FFD235"><b style="font-size: 12px; color: #877503">WM</b></div>`
                            }
                            if (p.type.includes(FUNCTION_TYPE.EWM)) {
                                collectionEmailFormat += `<div style="border-radius: 13px; margin-left: 12px; box-sizing: border-box; padding: 0px 8px 0px 8px; background-color: #05FFA4"><b style="font-size: 12px; color: #008051">EWM</b></div>`
                            }
                            if (p.type.includes(FUNCTION_TYPE.QM)) {
                                collectionEmailFormat += `<div style="border-radius: 13px; margin-left: 12px; box-sizing: border-box; padding: 0px 8px 0px 8px; background-color: #383D47"><b style="font-size: 12px; color: #ffffff">QM</b></div>`
                            }
                            collectionEmailFormat += `</div><span style="font-size: 10px; margin-left: 12px">notes: ${p.notes}</span></li>`
                        }

                        if (index === c.processes.length - 1) {
                            collectionEmailFormat += `</ul>`
                        }
                    })
                }
            }
        })
        collectionEmailFormat += `</ul>`
    })


    return collectionEmailFormat
}

export const getUpperBoundValue = (value, roundConstant = 5) => {
    const powerOfTens = Math.floor(Math.log10(value));
    const roundingFactor = roundConstant * Math.pow(10, value >= 100 ? powerOfTens - 1 : powerOfTens);
    const costUpperBound = Math.ceil(value / roundingFactor) * roundingFactor;

    return costUpperBound;
}

export const getTicks = (upperBoundValue, gridCount = 5) => {
    const interval = upperBoundValue / gridCount;
    let value = 0;
    let costTicks = [];

    while (value <= upperBoundValue) {
        costTicks.push(value);
        value += interval;
    }

    return costTicks;
}

export const getLastNYear = (n = 5) => {
    const date = new Date();
    let year = date.getFullYear();

    const years = [];
    while (--n > 0) {
        years.push(--year);
    }

    return years;
}

export const generateDropDownItems = (source, onClick) => {
    const dropDownItems = [];
    for (const element of source) {
        const click = () => onClick(element);
        dropDownItems.push({ text: element, onClick: click });
    }

    return dropDownItems;
}

export const formatStringUppercaseFirstLetterAndLowercase = string => {
    return string
        .replace(/(\B)[^ ]*/g, match => (match.toLowerCase()))
        .replace(/^[^ ]/g, match => (match.toUpperCase()))
}

export const initializeDeviceFilter = devices => {
    const applicationsName = []
    const result = []

    /** Get all application names */
    devices.forEach(device => {
        if (device.apps.length > 0) {
            device.apps.forEach(deviceApp => {
                if (!applicationsName.includes(deviceApp.name)) {
                    applicationsName.push(deviceApp.name)
                }
            })
        }
    })

    applicationsName.forEach(appName => {
        result.push({
            name: appName,
            versions: [],
            selectedVersion: null,
            isActive: false
        })
    })

    devices.forEach(device => {
        if (device.apps.length > 0) {
            result.forEach(r => {
                device.apps.forEach(deviceApp => {
                    if (r.name === deviceApp.name) {
                        if (!r.versions.includes(deviceApp.versionName)) {
                            r.versions.push(deviceApp.versionName)
                        }
                    }
                })
            })
        }
    })

    return result
}

export const formatNumericalWithThousandSeparator = value => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const fontSizeTertiaryButtonIcon = size => {
    switch (size) {
        case 'xsmall':
            return 14
        case 'small':
            return 14
        case 'medium':
            return 18
        case 'large':
            return 24
    }
}

export const iconSizeTertiaryButtonIcon = size => {
    switch (size) {
        case 'xsmall':
            return 14
        case 'small':
            return 16
        case 'medium':
            return 22
        case 'large':
            return 38
    }
}