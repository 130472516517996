import React, { useContext, useEffect, useState } from "react"

import { ColorSAP, FONT_FAMILY, Typography, UserGroup } from "@commsult/ontego-ui"
import { Delete } from "@commsult/ontego-ui/dist/sap_icons"
import { Box, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { AppContext } from "../../../App"
import { ACTION_TYPE_ADD_CLIENT } from "../../../Constants/DashboardActionType"
import DeleteDialog from "../../../Dialogs/DeleteDialog/DeleteDialog"
import Button from "../../../ReusableComponents/Button/Button"
import Chip from "../../../ReusableComponents/Chip/Chip"
import Form from "../../../ReusableComponents/Form/Form"
import AdminUserService from "../AdminUserService"
import ClientList from "./ClientList"

const ClientListWidget = props => {
    const {
        handleShowErrorSnackbar,
        handleShowSnackbar
    } = useContext(AppContext)

    const { t } = useTranslation()

    const {
        isReload,
        setIsReload,
        inspectedData,
        setInspectedData,
        handleOpenClientDialog,
        handleOpenReportDialog,
        handleOpenBillingDialog,
        handleClickShowMoreIcon
    } = props

    const [clients, setClients] = useState([])
    const [filteredClients, setFilteredClients] = useState([])
    const [loading, setLoading] = useState(false)

    const [search, setSearch] = useState("")

    const [quickFilter, setQuickFilter] = useState([])

    const [deleteClientDialogOpen, setDeleteClientDialogOpen] = useState(false)

    useEffect(() => {
        if (!isReload) {
            setLoading(true)
            AdminUserService.getAllClients().then((res) => {
                setClients(res.data)

                const newFilteredClients = [...res.data]

                setFilteredClients(newFilteredClients.filter((data) =>
                    data.name.toLowerCase().includes(search) ||
                    data.email.toLowerCase().includes(search) ||
                    data.roles?.toLowerCase().includes(search) ||
                    data.ontegoid?.toLowerCase().includes(search)
                ).filter(filterByStatus))

                setLoading(false)
            }).catch(err => {
                handleShowErrorSnackbar(err)
                setLoading(false)
            })
        }

        return () => {
            setIsReload(false)
        }
    }, [isReload])

    useEffect(() => {
        setLoading(true)

        const newFilteredClients = [...clients]

        setFilteredClients(newFilteredClients.filter((data) =>
            data.name.toLowerCase().includes(search) ||
            data.email.toLowerCase().includes(search) ||
            data.roles?.toLowerCase().includes(search) ||
            data.ontegoid?.toLowerCase().includes(search)
        ).filter(filterByStatus))

        setLoading(false)
    }, [quickFilter, search])

    const handleOpenDeleteClientDialog = client => () => {
        setInspectedData(client)
        setDeleteClientDialogOpen(true)
    }

    const handleCloseDeleteClientDialog = () => setDeleteClientDialogOpen(false)

    const filterByStatus = (client) => {
        if (quickFilter.includes('c') && quickFilter.includes('p') || quickFilter.length === 0) {
            return true
        }
        else if (quickFilter.includes('c')) {
            return client.hasPurchased
        }
        else if (quickFilter.includes('p')) {
            return !client.hasPurchased
        }
    }

    const handleSelectQuickFilter = (type) => () => {
        const newQuickFilter = [...quickFilter]

        if (!newQuickFilter.includes(type)) {
            newQuickFilter.push(type)
        }
        else {
            newQuickFilter.forEach((filter, index) => {
                if (filter === type) {
                    newQuickFilter.splice(index, 1)
                }
            })
        }

        setQuickFilter(newQuickFilter)
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault()

        const searchVal = document.getElementById(`client-search-form`).value
        setSearch(searchVal)
    }

    const handleDeleteClient = () => {
        if (inspectedData && inspectedData.key) {
            setLoading(true)
            AdminUserService.deleteClient(inspectedData.key).then(res => {
                setIsReload(true)
                setLoading(false)
                handleShowSnackbar({
                    message: t("manage.objectDeleted", { object: `${t('adminDashboard.clients')} ${res.data.name}` }),
                    autoHideDuration: 3000,
                    icon: <Delete />,
                    showButton: true,
                })
            }).catch(err => {
                handleShowErrorSnackbar(err)
                setLoading(false)
            })
        }
        else {
            handleShowErrorSnackbar(`Cannot process request due to missing data`);
        }
    }

    return (
        <Box
            sx={{
                border: `1px solid rgb(208, 208, 208)`,
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 8px',
                borderRadius: 16,
                width: "100%",
                boxSizing: "border-box",
                padding: '24px 18px'
            }}
        >

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        <UserGroup
                            style={{
                                width: 24,
                                height: 24,
                                color: ColorSAP.primary[70],
                                marginRight: 10,
                            }}
                        />
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={20}
                            color={ColorSAP.neutral[100]}
                        >
                            {t("adminDashboard.clients")}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={4}>
                    <form onSubmit={handleOnSubmit}>
                        <Form
                            id={`client-search-form`}
                            placeholder={t("common.search")}
                        />
                        <button type="submit" style={{ visibility: 'hidden' }} />
                    </form>
                </Grid>

                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button
                        type="primary"
                        theme="dark"
                        size="small"
                        onClick={handleOpenClientDialog(ACTION_TYPE_ADD_CLIENT, null)}
                    >
                        {t("button.addClient")}
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            padding: "4px 12px",
                            boxSizing: 'border-box',
                            width: 'fit-content',
                            height: 50,
                            borderRadius: 8,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={12}
                            color={ColorSAP.neutral[100]}
                        >
                            {t("adminDashboard.quickFilter")}
                        </Typography>

                        <Box
                            sx={{
                                marginLeft: 24
                            }}
                        >
                            <Chip
                                backgroundColor={quickFilter.includes('c') ? ColorSAP.primary[70] : ColorSAP.neutral[30]}
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                onClick={handleSelectQuickFilter('c')}
                            >
                                {`Kunden`}
                            </Chip>
                        </Box>

                        <Box
                            sx={{
                                marginLeft: 12
                            }}
                        >
                            <Chip
                                backgroundColor={quickFilter.includes('p') ? ColorSAP.primary[70] : ColorSAP.neutral[30]}
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                                onClick={handleSelectQuickFilter('p')}
                            >
                                {`Interessent`}
                            </Chip>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ClientList
                        clients={filteredClients}
                        loading={loading}
                        handleClickShowMoreIcon={handleClickShowMoreIcon}
                        handleOpenReportDialog={handleOpenReportDialog}
                        handleOpenBillingDialog={handleOpenBillingDialog}
                        handleOpenClientDialog={handleOpenClientDialog}
                        handleOpenDeleteClientDialog={handleOpenDeleteClientDialog}
                    />
                </Grid>
            </Grid>

            <DeleteDialog
                open={deleteClientDialogOpen}
                onClose={handleCloseDeleteClientDialog}
                onDelete={handleDeleteClient}
                title={`${t('adminDashboard.clients')} ${inspectedData?.name ?? '-'}`}
                dataName={`${t('adminDashboard.clients')} ${inspectedData?.name ?? '-'}`}
            />
        </Box>
    )
}

export default ClientListWidget