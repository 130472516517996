import React from 'react';

import { OutlinedInput } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { ColorSAP, FONT_FAMILY, Typography } from '@commsult/ontego-ui';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: ColorSAP.neutral[10],
        "&:hover $notchedOutline": {
            borderColor: ColorSAP.neutral[40]
        },
        "&$focused $notchedOutline": {
            borderWidth: "1px !important",
            borderColor: ColorSAP.neutral[100]
        }
    },
    rootWhite: {
        width: '100%',
        background: ColorSAP.neutral[0],
    },
    input: {
        border: 'none',
        background: ColorSAP.neutral[10],
        fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR,
        fontSize: 14,
        color: ColorSAP.neutral[100],
        '&::placeholder': {
            fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR,
            fontSize: 14,
            color: `${ColorSAP.neutral[40]} !important`,
            opacity: 1
        }
    },
    inputWhite: {
        border: 'none',
        background: ColorSAP.neutral[0],
        fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR,
        fontSize: 14,
        color: ColorSAP.neutral[100]
    },
    notchedOutline: {
        // borderWidth: "1px !important",
        // borderColor: `${ColorSAP.neutral[100]} !important`,
        borderColor: ColorSAP.neutral[40]
    },
    focused: {
        border: 'none'
    },
    adornedStart: {
        "& svg": {
            width: 24,
            height: 24,
            color: ColorSAP.neutral[40],
            marginRight: 12
        }
    },
}));

const Form = props => {
    const { id, multiline, rows, placeholder, label, containerStyle, value, onChange, onBlur, isPassword, whiteBackground, startAdornment, endAdornment, error, errorMessage, required, onClick, select, number } = props
    const classes = useStyles();

    return (
        <div
            style={{
                width: '100%',
                cursor: select && 'pointer',
                ...containerStyle
            }}
            onClick={onClick}
        >
            <Typography
                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                size={14}
                color={error ? ColorSAP.danger[60] : ColorSAP.neutral[100]}
                style={{ marginBottom: 12 }}
            >
                {label}{required ? "*" : ""}
            </Typography>
            <OutlinedInput
                id={id}
                variant="outlined"
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                classes={{
                    root: whiteBackground ? classes.rootWhite : classes.root,
                    input: whiteBackground ? classes.inputWhite : classes.input,
                    notchedOutline: classes.notchedOutline,
                    focused: classes.focused,
                    adornedStart: classes.adornedStart
                }}
                value={value}
                multiline={multiline}
                rows={rows}
                placeholder={placeholder}
                error={error}
                onChange={onChange}
                onBlur={onBlur}
                type={isPassword ? 'password' : (number ? 'number' : 'text')}
            />
            {error && errorMessage !== "" && (
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                    size={12}
                    color={ColorSAP.danger[60]}
                    style={{ marginTop: 4 }}
                >
                    {errorMessage}
                </Typography>
            )}
        </div>
    )
}

export default Form