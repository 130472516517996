import React from 'react';

import { useStyles } from './SpinnerStyles';

import { Loading } from '@commsult/ontego-ui';

const Spinner = props => {
    const { style } = props

    const classes = useStyles(props)

    return (
        <div className={classes.loading}>
            <Loading style={{ ...style }} />
        </div>
    )
}

export default Spinner