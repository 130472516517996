import React from "react";

import { ColorSAP, FONT_FAMILY, Typography } from "@commsult/ontego-ui";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Delete, Edit } from "@commsult/ontego-ui/dist/sap_icons";
import moment from "moment";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { ACTION_TYPE_UPDATE_BILLING } from "../../../Constants/DashboardActionType";
import IconButton from "../../../ReusableComponents/IconButton/IconButton";
import Spinner from "../../../ReusableComponents/Spinner/Spinner";
import { formatNumericalWithThousandSeparator } from "../../../Utils/Utils";

const GRID_TEMPLATE_COLUMNS = `30px 100px 200px 80px 120px 150px auto`
const GRID_COLUMN_GAP = `8px`

const Header = () => {
    const { t } = useTranslation()

    return (
        <Box
            sx={{
                padding: '0px 12px',
                boxSizing: 'border-box',
                width: '100%',
                height: 40,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                background: ColorSAP.primary[20],
                display: 'grid',
                alignItems: 'center',
                gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
                gridColumnGap: GRID_COLUMN_GAP
            }}
        >
            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {`#`}
                </Typography>
            </Box>

            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {`Billing Type`}
                </Typography>
            </Box>

            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {`Period`}
                </Typography>
            </Box>

            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {`Base Fee`}
                </Typography>
            </Box>

            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {`Included Device`}
                </Typography>
            </Box>

            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {`Additional Device Cost`}
                </Typography>
            </Box>
        </Box>
    )
}

const BillingManagementList = props => {
    const {
        client,
        billings,
        loading,
        handleOpenBillingPeriodDialog,
        handleDeleteBillingPeriod
    } = props

    const { t } = useTranslation()

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 40,
        minHeight: 40
    })

    const virtualMessageRowRenderer = ({
        key, // Unique key within array of rows
        index, // Index of row within collection
        parent,
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style // Style object to be applied to row (to position it)
    }) => {
        return (
            <CellMeasurer
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
            >
                <div style={style} key={key}>
                    <Box
                        sx={{
                            padding: '0px 12px',
                            height: '40px',
                            display: 'grid',
                            alignItems: 'center',
                            gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
                            gridColumnGap: GRID_COLUMN_GAP,
                            borderBottom: `1px solid ${ColorSAP.neutral[20]}`,
                            background: index % 2 !== 0 ? ColorSAP.neutral[10] : ColorSAP.neutral[0],
                            transition: '0.15s all ease-in-out',
                            '&:hover': {
                                transition: '0.15s all ease-in-out',
                                background: ColorSAP.primary[10],
                                borderBottom: `1px solid ${ColorSAP.neutral[30]}`,
                                "& .client-actions": {
                                    opacity: 1
                                }
                            }
                        }}
                    >
                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                size={12}
                                color={ColorSAP.neutral[100]}
                            >
                                {index + 1}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                size={12}
                                color={ColorSAP.neutral[100]}
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {billings[index].billing_type ?? '-'}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={12}
                                color={ColorSAP.neutral[100]}
                            >
                                {`${moment(billings[index].period_start, 'YYYY-MM-DD').format('DD.MM.YYYY')} - ${moment(billings[index].period_end, 'YYYY-MM-DD').format('DD.MM.YYYY')}`}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={12}
                                color={ColorSAP.neutral[100]}
                            >
                                {`${formatNumericalWithThousandSeparator(billings[index].billing_info.base_fee)} EUR`}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={12}
                                color={ColorSAP.neutral[100]}
                            >
                                {billings[index].billing_info.included_device}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={12}
                                color={ColorSAP.neutral[100]}
                            >
                                {`${formatNumericalWithThousandSeparator(billings[index].billing_info.additional_device_fee)} EUR`}
                            </Typography>
                        </Box>

                        <Box
                            className="client-actions"
                            sx={{
                                opacity: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <IconButton
                                textTooltip={`Edit Billing Period`}
                                color="primary"
                                icon={<Edit />}
                                onClick={handleOpenBillingPeriodDialog(ACTION_TYPE_UPDATE_BILLING, client, billings[index])}
                            />

                            <IconButton
                                textTooltip={`Delete Billing Period`}
                                rootSx={{
                                    marginLeft: 8
                                }}
                                color="danger"
                                icon={<Delete />}
                                onClick={handleDeleteBillingPeriod(billings[index].id)}
                            />
                        </Box>
                    </Box>
                </div>
            </CellMeasurer>
        )
    }

    return (
        <>
            <Box sx={{ marginTop: 16 }} />
            <Header />
            <Box
                sx={{
                    width: '100%',
                    height: 225,
                    background: ColorSAP.neutral[5]
                }}
            >
                {loading ? (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Spinner
                            style={{
                                width: 80,
                                height: 80,
                                color: ColorSAP.primary[70]
                            }}
                        />
                    </Box>
                ) : (
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                rowCount={billings.length}
                                width={width}
                                height={height}
                                deferredMeasurementCache={cache}
                                rowHeight={cache.rowHeight}
                                rowRenderer={virtualMessageRowRenderer}
                                overscanRowCount={3}
                            />
                        )}
                    </AutoSizer>
                )}
            </Box>
        </>
    )
}

export default BillingManagementList