import {
    Edit,
    Plus
} from '@commsult/ontego-ui';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App';
import { getUserRoleObjectFromValue, getUserStatusFromFlag, USER_ROLE, USER_STATUS } from '../../Constants/ClientRoles';
import { ACTION_TYPE_ADD_CLIENT, ACTION_TYPE_UPDATE_CLIENT } from '../../Constants/DashboardActionType';
import AdminUserService from '../../Pages/AdminDashboard/AdminUserService';
import Button from '../../ReusableComponents/Button/Button';
import DialogBox from '../../ReusableComponents/DialogBox/DialogBox';
import Form from '../../ReusableComponents/Form/Form';
import Select from '../../ReusableComponents/Select/Select';
import Spinner from '../../ReusableComponents/Spinner/Spinner';
import TertiaryButton from '../../ReusableComponents/TertiaryButton/TertiaryButton';

const ClientDialog = props => {
    const { open, onClose, isReload, handleReloadData, actionType, inspectedData } = props;
    const { t } = useTranslation();

    const {
        handleShowErrorSnackbar, handleShowSnackbar
    } = useContext(AppContext);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [role, setRole] = useState(USER_ROLE[0]);
    const [status, setStatus] = useState(USER_STATUS[0]);

    const [minimumDevice, setMinimumDevice] = useState(5);
    const [deviceLimit, setDeviceLimit] = useState(10);

    const [nameError, setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(false)
        if (actionType === ACTION_TYPE_UPDATE_CLIENT) {
            setName(inspectedData.name)
            setEmail(inspectedData.email)
            setRole(getUserRoleObjectFromValue(inspectedData.roles))
            setMinimumDevice(inspectedData.minimumDevice)
            setDeviceLimit(inspectedData.deviceLimit)
            setStatus(getUserStatusFromFlag(inspectedData.hasPurchased))
        } else if (actionType === ACTION_TYPE_ADD_CLIENT) {
            setName("")
            setEmail("")
            setRole(USER_ROLE[0])
            setMinimumDevice(5)
            setDeviceLimit(10)
            setStatus(USER_STATUS[0])
        }
    }, [open])

    const handleChangeName = e => {
        setName(e.currentTarget.value)
        setNameError(false)
    };

    const handleChangeEmail = e => {
        setEmail(e.currentTarget.value)
        setEmailError(false)
    };

    const handleChangePassword = e => {
        setPassword(e.currentTarget.value)
        setPasswordError(false)
    };

    const handleChangeConfirmPassword = e => {
        setConfirmPassword(e.currentTarget.value)
        setConfirmPasswordError(false)
    };

    const handleChangeMinimumDevice = e => {
        setMinimumDevice(e.currentTarget.value)
    }

    const handleChangeDeviceLimit = e => {
        setDeviceLimit(e.currentTarget.value)
    }

    const handleChangeRole = val => {
        setRole(val)
    }

    const handleChangeStatus = val => {
        setStatus(val)
    }

    const handleSubmit = e => {
        e.preventDefault()
        const hasError = errorValidation()

        setLoading(true)

        if (!hasError) {
            const formModel = {
                email: email,
                name: name,
                password: password,
                role: role.value,
                minimumDevice: minimumDevice,
                deviceLimit: deviceLimit,
                isHasPurchased: status.value === 'PROSPECT' ? false : true
            }

            if (actionType === ACTION_TYPE_ADD_CLIENT) {
                AdminUserService.createNewClient(formModel).then(res => {
                    handleShowSnackbar({
                        message: t("manage.objectCreated", { object: name }),
                        autoHideDuration: 3000,
                        icon: <Plus />,
                        showButton: true,
                    })
                    setLoading(false)
                    handleReloadData()
                    handleClose();
                }).catch(err => {
                    handleShowErrorSnackbar(err);
                })
            } 
            else if (actionType === ACTION_TYPE_UPDATE_CLIENT) {
                AdminUserService.updateClient(inspectedData.key, formModel).then(res => {
                    handleShowSnackbar({
                        message: t("manage.objectEdited", { object: name }),
                        autoHideDuration: 3000,
                        icon: <Edit />,
                        showButton: true,
                    })
                    setLoading(false)
                    handleReloadData()
                    handleClose()
                }).catch(err => {
                    handleShowErrorSnackbar(err)
                })
            }
        }
        else {
            setLoading(false)
        }
    }

    const handleClose = () => {
        onClose()

        setName("")
        setEmail("")
        setPassword("")
        setConfirmPassword("")
        setMinimumDevice(0)
        setDeviceLimit(0)

        setNameError(false)
        setEmailError(false)
        setPasswordError(false)
        setConfirmPasswordError(false)
    }

    const errorValidation = () => {
        let hasError = false

        if (actionType !== ACTION_TYPE_ADD_CLIENT) {
            if (name === "") {
                setNameError(true);
                hasError = true;
            }
        }

        if (email === "") {
            setEmailError(true);
            hasError = true;
        }

        if (actionType === ACTION_TYPE_ADD_CLIENT) {
            if (password === "") {
                setPasswordError(true);
                hasError = true;
            }

            if (confirmPassword === "" || confirmPassword !== password) {
                setConfirmPasswordError(true);
                hasError = true;
            }
        }

        if (actionType === ACTION_TYPE_UPDATE_CLIENT) {
            if (confirmPassword !== password) {
                setConfirmPasswordError(true);
                hasError = true;
            }
        }

        return hasError;
    }

    const titleForm = {
        ADD: t("adminDashboard.addNewClient"),
        ADD_CLIENT: t("adminDashboard.createNewClientCredential"),
        UPDATE_CLIENT: t("adminDashboard.updateClientData"),
    }

    const buttonText = {
        ADD: t("adminDashboard.addNewUser"),
        ADD_CLIENT: t("adminDashboard.addNewUser"),
        UPDATE_CLIENT: t("manage.ok"),
    }

    return (
        <>
            <DialogBox
                open={open}
                handleClose={handleClose}
                textTitle={titleForm[actionType]}
                content={
                    <div style={{ width: 750, paddingLeft: 24, paddingRight: 24, paddingBottom: 40, boxSizing: 'border-box' }}>
                        <Box
                            sx={{
                                height: loading ? 250 : '100%',
                                width: '100%',
                                display: loading ? 'flex' : 'auto',
                                alignItems: loading ? 'center' : 'auto',
                                justifyContent: loading ? 'center' : 'auto',
                                marginTop: 24
                            }}
                        >
                            {loading ? (
                                <Spinner
                                    style={{
                                        width: 100,
                                        height: 100
                                    }}
                                />
                            ) : (
                                <Grid container spacing={2} style={{ flexGrow: 1 }}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <div style={{ marginTop: 24 }}>
                                            <Form
                                                label={t("manage.name")}
                                                placeholder="Name"
                                                required
                                                value={name}
                                                onChange={handleChangeName}
                                                error={nameError}
                                                errorMessage={nameError && t(`manage.fieldRequired`)}
                                                tabIndex={1}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6}>
                                        <div style={{ marginTop: 24 }}>
                                            <Form
                                                label={t("common.email")}
                                                placeholder="E-mail"
                                                required
                                                value={email}
                                                onChange={handleChangeEmail}
                                                error={emailError}
                                                errorMessage={emailError && t(`manage.fieldRequired`)}
                                                tabIndex={1}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6}>
                                        <div style={{ marginTop: 24 }}>
                                            <Form
                                                isPassword
                                                label={t("common.password")}
                                                placeholder="Password"
                                                value={password}
                                                onChange={handleChangePassword}
                                                error={passwordError}
                                                errorMessage={passwordError && t(`manage.fieldRequired`)}
                                                required={actionType === ACTION_TYPE_ADD_CLIENT ? true : false}
                                                tabIndex={2}
                                                autoComplete="new-password"
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6}>
                                        <div style={{ marginTop: 24 }}>
                                            <Form
                                                isPassword
                                                label={t("common.confirmPassword")}
                                                placeholder={t("common.confirmPassword")}
                                                value={confirmPassword}
                                                onChange={handleChangeConfirmPassword}
                                                error={confirmPasswordError}
                                                errorMessage={confirmPasswordError && t(`manage.fieldRequired`)}
                                                required={actionType === ACTION_TYPE_ADD_CLIENT ? true : false}
                                                tabIndex={2}
                                                disabled={actionType === ACTION_TYPE_UPDATE_CLIENT && password === "" ? true : false}
                                                autoComplete="new-password"
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={3} md={3}>
                                        <div style={{ marginTop: 24 }}>
                                            <Select
                                                label={t("common.userRole")}
                                                placeholder={t("common.userRole")}
                                                onSelect={handleChangeRole}
                                                value={role}
                                                item={USER_ROLE}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={3} md={3}>
                                        <div style={{ marginTop: 24 }}>
                                            <Select
                                                label={t("common.userStatus")}
                                                placeholder={t("common.userStatus")}
                                                onSelect={handleChangeStatus}
                                                value={status}
                                                item={USER_STATUS}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={3} md={3}>
                                        <div style={{ marginTop: 24 }}>
                                            <Form
                                                label={t("manage.minimumDevice")}
                                                placeholder="Minimum Device"
                                                required
                                                value={minimumDevice}
                                                onChange={handleChangeMinimumDevice}
                                            // error={emailError}
                                            // errorMessage={emailError && t(`manage.fieldRequired`)}
                                            // tabIndex={1}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={3} md={3}>
                                        <div style={{ marginTop: 24 }}>
                                            <Form
                                                label={t("manage.deviceLimit")}
                                                placeholder="Device Limit"
                                                required
                                                value={deviceLimit}
                                                onChange={handleChangeDeviceLimit}
                                            // error={emailError}
                                            // errorMessage={emailError && t(`manage.fieldRequired`)}
                                            // tabIndex={1}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item md={12}>
                                        <div tabIndex={3} style={{ marginTop: 24, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <TertiaryButton
                                                theme="light"
                                                size="small"
                                                onClick={handleClose}
                                            >
                                                {`Cancel`}
                                            </TertiaryButton>
                                            <Button
                                                type="primary"
                                                theme="dark"
                                                size="small"
                                                onClick={handleSubmit}
                                            >
                                                {buttonText[actionType]}
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </div>
                }
            />
        </>
    )
}

export default ClientDialog;