import React, { useEffect, useState } from "react"

import { ColorSAP, FONT_FAMILY, Globe, Typography, UserGroup } from "@commsult/ontego-ui"
import { Disabled, User } from "@commsult/ontego-ui/dist/sap_icons"
import { Box } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { BLACKLISTED_DEVICE } from "../../../Constants/Path"
import MenuCard from "../../../ReusableComponents/MenuCard/MenuCard"
import AdminUserService from "../AdminUserService"

const OverviewWidget = props => {
    const history = useHistory()
    const { t } = useTranslation()

    const getLabel = (id) => {
        switch (id) {
            case 1:
                return t(`adminDashboard.totalClients`)
            case 2:
                return t(`adminDashboard.totalProspects`)
            case 3:
                return t(`manage.blacklistedDevice`)
        }
    }

    const [menus, setMenus] = useState([
        {
            id: 1,
            icon: <UserGroup />,
            value: 0,
            onClick: null
        },
        {
            id: 2,
            icon: <User />,
            value: 0,
            onClick: null
        },
        {
            id: 3,
            icon: <Disabled />,
            value: 0,
            onClick: () => {
                history.push(BLACKLISTED_DEVICE)
            }
        }
    ])

    useEffect(() => {
        const newMenus = [...menus]

        AdminUserService.getOverviewClientCount(true)
            .then(res => {
                if (res.status === 200) {
                    newMenus.forEach(menu => {
                        if (menu.id === 1) {
                            menu.value = res.data.count
                        }
                    })
                    setMenus(menus)
                }
            })
            .catch(err => {
                console.log(err)
            })

        AdminUserService.getOverviewClientCount(false)
            .then(res => {
                if (res.status === 200) {
                    newMenus.forEach(menu => {
                        if (menu.id === 2) {
                            menu.value = res.data.count
                        }
                    })
                    setMenus(menus)
                }
            })
            .catch(err => {
                console.log(err)
            })

        AdminUserService.getOverviewBlacklistedDeviceCount()
            .then(res => {
                if (res.status === 200) {
                    newMenus.forEach(menu => {
                        if (menu.id === 3) {
                            menu.value = res.data.count
                        }
                    })
                    setMenus(menus)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <Box
            sx={{
                border: `1px solid rgb(208, 208, 208)`,
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 8px',
                borderRadius: 16,
                width: "100%",
                boxSizing: "border-box",
                padding: '24px 18px'
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center"
                }}
            >
                <Globe
                    style={{
                        width: 24,
                        height: 24,
                        color: ColorSAP.primary[70],
                        marginRight: 12,
                    }}
                />
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={20}
                    color={ColorSAP.neutral[100]}
                >
                    {t(`adminDashboard.overview`)}
                </Typography>
            </Box>

            <Box
                style={{
                    marginTop: 24,
                    display: 'flex'
                }}
            >
                {menus.map((menu, index) => (
                    <Box
                        sx={{
                            marginLeft: index > 0 && 12
                        }}
                    >
                        <MenuCard
                            label={getLabel(menu.id)}
                            value={menu.value}
                            icon={menu.icon}
                            onClick={menu.onClick}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default OverviewWidget