export const ROLE_ADMIN = "ADMIN";
export const ROLE_USER = "USER";

export const USER_ROLE = [
    {
        value: "USER",
        label: "User"
    },
    {
        value: "ADMIN",
        label: "Admin"
    }
]

export const USER_STATUS = [
    {
        value: "PROSPECT",
        label: "Interessent"
    },
    {
        value: "CLIENT",
        label: "Kunde"
    }
]

export const getUserRoleObjectFromValue = val => {
    return USER_ROLE.filter(data => data.value === val)[0]
}

export const getUserStatusFromFlag = ishaspurchased => {
    return ishaspurchased ? USER_STATUS[1] : USER_STATUS[0]
}