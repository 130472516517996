import React from "react"

import { ColorSAP, FONT_FAMILY, Typography } from "@commsult/ontego-ui"
import { ArrowRight } from "@commsult/ontego-ui/dist/sap_icons"
import { Box } from "@material-ui/core"

const MenuCard = props => {
    const { label, icon, value, onClick } = props

    return (
        <Box
            sx={{
                userSelect: 'none',
                position: 'relative',
                width: 'fit-content',
                padding: '8px 16px',
                boxSizing: 'border-box',
                background: ColorSAP.secondary[10],
                border: `2px solid ${ColorSAP.secondary[20]}`,
                borderRadius: 8,
                cursor: (onClick && typeof onClick !== 'undefined') ? 'pointer' : 'auto',
                transition: 'all 0.15s ease-in-out',
                "&:hover": {
                    border: (onClick && typeof onClick !== 'undefined') && `2px solid ${ColorSAP.secondary[30]}`,
                    background: (onClick && typeof onClick !== 'undefined') && ColorSAP.secondary[30],
                    transition: 'all 0.15s ease-in-out'
                }
            }}
            onClick={onClick}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        marginLeft: -6,
                        height: 40,
                        "& svg": {
                            width: 40,
                            height: 40,
                            color: ColorSAP.secondary[80]
                        }
                    }}
                >
                    {icon}
                </Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                    size={24}
                    color={ColorSAP.secondary[80]}
                    style={{ marginLeft: 6 }}
                >
                    {label}
                </Typography>
            </Box>
            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={48}
                    color={ColorSAP.secondary[80]}
                >
                    {value}
                </Typography>
            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    "& svg": {
                        width: 40,
                        height: 40,
                        color: ColorSAP.secondary[60]
                    }
                }}
            >
                {(onClick && typeof onClick !== 'undefined') && (
                    <ArrowRight />
                )}
            </Box>
        </Box>
    )
}

export default MenuCard