import React from "react";

import { ColorSAP, Download, FONT_FAMILY, Typography } from "@commsult/ontego-ui";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Delete } from "@commsult/ontego-ui/dist/sap_icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import BillingPDF from "../../../Pages/BillingReport/PDF/BillingPDF";
import IconButton from "../../../ReusableComponents/IconButton/IconButton";
import Spinner from "../../../ReusableComponents/Spinner/Spinner";
import { formatNumericalWithThousandSeparator } from "../../../Utils/Utils";

const GRID_TEMPLATE_COLUMNS = `30px 180px 150px 200px 100px auto`
const GRID_COLUMN_GAP = `8px`

const Header = () => {
    const { t } = useTranslation()

    return (
        <Box
            sx={{
                padding: '0px 12px',
                boxSizing: 'border-box',
                width: '100%',
                height: 40,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                background: ColorSAP.primary[20],
                display: 'grid',
                alignItems: 'center',
                gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
                gridColumnGap: GRID_COLUMN_GAP
            }}
        >
            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {`#`}
                </Typography>
            </Box>

            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {`Billing File`}
                </Typography>
            </Box>

            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {`Generated at`}
                </Typography>
            </Box>

            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {`Period`}
                </Typography>
            </Box>

            <Box>
                <Typography
                    fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                    size={12}
                    color={ColorSAP.neutral[100]}
                >
                    {`Total Price`}
                </Typography>
            </Box>
        </Box>
    )
}

const BillingReportList = props => {
    const {
        client,
        billingReports,
        loading,
        handleDeleteBillingReport
    } = props

    const { t } = useTranslation()

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 40,
        minHeight: 40
    })

    const virtualMessageRowRenderer = ({
        key, // Unique key within array of rows
        index, // Index of row within collection
        parent,
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style // Style object to be applied to row (to position it)
    }) => {
        return (
            <CellMeasurer
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
            >
                <div style={style} key={key}>
                    <Box
                        sx={{
                            padding: '0px 12px',
                            height: '40px',
                            display: 'grid',
                            alignItems: 'center',
                            gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
                            gridColumnGap: GRID_COLUMN_GAP,
                            borderBottom: `1px solid ${ColorSAP.neutral[20]}`,
                            background: index % 2 !== 0 ? ColorSAP.neutral[10] : ColorSAP.neutral[0],
                            transition: '0.15s all ease-in-out',
                            '&:hover': {
                                transition: '0.15s all ease-in-out',
                                background: ColorSAP.primary[10],
                                borderBottom: `1px solid ${ColorSAP.neutral[30]}`,
                                "& .client-actions": {
                                    opacity: 1
                                }
                            }
                        }}
                    >
                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                size={12}
                                color={ColorSAP.neutral[100]}
                            >
                                {index + 1}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_SEMIBOLD}
                                size={12}
                                color={ColorSAP.neutral[100]}
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {`${client?.name.replace(/ /g, '').toUpperCase()}_${moment(billingReports[index].created_on).format('x')}`}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={12}
                                color={ColorSAP.neutral[100]}
                            >
                                {moment(billingReports[index].created_on).format("DD.MM.YYYY HH:mm")}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={12}
                                color={ColorSAP.neutral[100]}
                            >
                                {billingReports[index].period}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography
                                fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR}
                                size={12}
                                color={ColorSAP.neutral[100]}
                            >
                                {`${formatNumericalWithThousandSeparator(billingReports[index].total_price.replace('.', ','))} EUR`}
                            </Typography>
                        </Box>

                        <Box
                            className="client-actions"
                            sx={{
                                opacity: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <PDFDownloadLink
                                document={
                                    <BillingPDF
                                        name={`${client?.name.replace(/ /g, '').toUpperCase()}_${moment(billingReports[index].created_on).format('x')}`}
                                        client={client}
                                        data={billingReports[index]}
                                    />
                                }
                                fileName={`${client?.name.replace(/ /g, '').toUpperCase()}_${moment(billingReports[index].created_on).format('x')}`}
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? (
                                        <Spinner />
                                    ) :
                                        (
                                            <IconButton
                                                textTooltip={`Download PDF`}
                                                color="primary"
                                                icon={<Download />}
                                            />
                                        )
                                }
                            </PDFDownloadLink>

                            <IconButton
                                textTooltip={`Delete Report`}
                                rootSx={{
                                    marginLeft: 8
                                }}
                                color="danger"
                                icon={<Delete />}
                                onClick={handleDeleteBillingReport(billingReports[index].id)}
                            />
                        </Box>
                    </Box>
                </div>
            </CellMeasurer>
        )
    }

    return (
        <>
            <Box sx={{ marginTop: 16 }} />
            <Header />
            <Box
                sx={{
                    width: '100%',
                    height: 225,
                    background: ColorSAP.neutral[5]
                }}
            >
                {loading ? (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Spinner
                            style={{
                                width: 80,
                                height: 80,
                                color: ColorSAP.primary[70]
                            }}
                        />
                    </Box>
                ) : (
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                rowCount={billingReports.length}
                                width={width}
                                height={height}
                                deferredMeasurementCache={cache}
                                rowHeight={cache.rowHeight}
                                rowRenderer={virtualMessageRowRenderer}
                                overscanRowCount={3}
                            />
                        )}
                    </AutoSizer>
                )}
            </Box>
        </>
    )
}

export default BillingReportList