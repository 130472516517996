import React from "react"

import { ColorSAP, Sandbox } from "@commsult/ontego-ui"
import { Box } from "@material-ui/core"
import Tooltip from "../Tooltip/Tooltip"

export const IconButton = (props) => {
    const { id, color, size, disabled, icon, rootSx, textTooltip, tooltipPlacement, onClick, withTooltip, ref } = props

    return (
        <Box
            sx={{ ...rootSx }}
        >
            <Tooltip
                title={textTooltip}
                placement={tooltipPlacement}
                disabled={!withTooltip || disabled}
            >
                <Box
                    id={id}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        transition: 'all .2s ease-in-out',
                        width: 32,
                        height: 32,
                        '& svg': {
                            width: 24,
                            height: 24,
                            pointerEvents: 'none',
                            transition: '0.25s color',
                            color: color === "primary" ? ColorSAP.primary[40] : ColorSAP.danger[40]
                        },
                        '&:hover': {
                            background: color === "primary" ? ColorSAP.primary[20] : ColorSAP.danger[10],
                            '& svg': {
                                color: color === "primary" ? ColorSAP.primary[70] : ColorSAP.danger[50]
                            },
                        },
                        '&:active': {
                            background: color === "primary" ? ColorSAP.primary[20] : ColorSAP.danger[10],
                            '& svg': {
                                color: color === "primary" ? ColorSAP.primary[100] : ColorSAP.danger[50]
                            }
                        }
                    }}
                    onClick={!disabled && onClick}
                    ref={ref}
                >
                    {icon}
                </Box>
            </Tooltip>
        </Box>
    )
}

IconButton.defaultProps = {
    color: "primary",
    icon: <Sandbox />,
    size: "small",
    textTooltip: 'Tooltip',
    tooltipPlacement: "bottom",
    disabled: false,
    withTooltip: true,
    onClick: () => { }
}

export default React.memo(IconButton)