import React, { useEffect, useState } from "react"

import { Bulb, ColorSAP, FONT_FAMILY, Typography } from "@commsult/ontego-ui"
import { Box, Grid } from "@material-ui/core"
import moment from "moment"
import { useTranslation } from "react-i18next"
import CustomDateRangePicker from "../../../ReusableComponents/Datepicker/CustomDateRangePicker"
import AdminUserService from "../AdminUserService"
import LoginCountList from "./LoginCountList"

const LoginCountListWidget = props => {
    const { t } = useTranslation()

    const [startDate, setStartDate] = useState(moment().subtract(1, 'months'))
    const [endDate, setEndDate] = useState(moment())

    const [loginCounts, setLoginCounts] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (startDate && endDate) {
            setLoading(true)
            AdminUserService.getProspectiveClientsLoginCount(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')).then((res) => {
                setLoginCounts(res.data)
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
            })
        }
    }, [startDate, endDate])

    const handleChangePeriod = ({ startDate, endDate }) => {
        setStartDate(startDate)
        setEndDate(endDate)
    }

    return (
        <Box
            sx={{
                border: `1px solid rgb(208, 208, 208)`,
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 8px',
                borderRadius: 16,
                width: "100%",
                boxSizing: "border-box",
                padding: '24px 18px'
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        <Bulb
                            style={{
                                width: 24,
                                height: 24,
                                color: ColorSAP.primary[70],
                                marginRight: 10,
                            }}
                        />
                        <Typography
                            fontFamily={FONT_FAMILY.PLUS_JAKARTA_SANS_BOLD}
                            size={20}
                            color={ColorSAP.neutral[100]}
                        >
                            {t('adminDashboard.interestedClientsLogIn')}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box
                        sx={{
                            marginTop: 16,
                            marginBottom: 24
                        }}
                    >
                        <CustomDateRangePicker
                            labelStart={`Start Date`}
                            labelEnd={`End Date`}
                            placeholderStart={`Select Start Date`}
                            placeholderEnd={`Select End Date`}
                            start={moment(startDate).format('DD.MM.YYYY')}
                            end={moment(endDate).format('DD.MM.YYYY')}
                            onChangeDate={handleChangePeriod}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <LoginCountList
                        loginCounts={loginCounts}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default LoginCountListWidget