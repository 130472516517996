import { ColorSAP, FONT_FAMILY } from '@commsult/ontego-ui';
import { ChevronDown } from '@commsult/ontego-ui/dist/sap_icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Form from '../Form/Form';

const StyledMenu = withStyles({
    paper: {
        marginTop: '6px',
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
        MenuListProps={{
            style: {
                width: props.width
            }
        }}
    />
));

const StyledMenuItem = withStyles((theme) => ({
}))(MenuItem);

const Select = props => {
    const { id, label, placeholder, item, value, onSelect } = props

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleSelect = value => () => {
        onSelect(value)
        handleClose()
    }

    return (
        <div>
            <Form
                select
                id={id}
                label={label}
                placeholder={placeholder}
                endAdornment={<ChevronDown style={{ width: 20, height: 20, color: ColorSAP.neutral[60] }} />}
                value={value?.label ?? ''}
                // required
                // value={email}
                // onChange={handleChangeEmail}
                // error={emailError}
                // errorMessage={emailError && t(`manage.fieldRequired`)}
                // tabIndex={1}
                onClick={handleClick}
            />
            <StyledMenu
                id={`${id}-selector-menu`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                width={anchorEl && anchorEl.offsetWidth}
            >
                {item.map(item => (
                    <StyledMenuItem
                        onClick={handleSelect(item)}
                        style={{
                            fontFamily: FONT_FAMILY.PLUS_JAKARTA_SANS_REGULAR,
                            fontSize: 14,
                            color: ColorSAP.neutral[100]
                        }}
                    >
                        {item.label}
                    </StyledMenuItem>
                ))}
            </StyledMenu>
        </div>
    );
}

export default Select